import React, { useEffect, useState } from 'react';

import { IMenuItem } from '@app/modules/main/menu-sidebar/MenuSidebar';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFilters } from '@app/modules/filter/filterContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useSubForm } from '@app/modules/SubFormContext';
import LibreForm from '../popup/libreForm';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';

const MenuItem = ({ menuItem }: { menuItem: IMenuItem }) => {
  const [t] = useTranslation();
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const filtersCTX = useFilters();
  const dataFactoryCTX = useDataFactory();
  const subFormCTX = useSubForm();
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLayoutFixed = useSelector((state: RootState) => state.ui.layoutBoxed);

  const handleMainMenuAction = () => {
    if (isExpandable) {
      setIsMenuExtended(!isMenuExtended);
      return;
    }
    if (menuItem.downloadPath) {
      const link = document.createElement('a');
      link.href = menuItem.downloadPath;
      link.download = menuItem.downloadPath;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (menuItem.setIsMenuHovered !== undefined && isMobile) {
        menuItem.setIsMenuHovered();
      }
      return;
    }
    if (menuItem.name === t('menusidebar.label.libre')) {
      if (menuItem.setIsMenuHovered !== undefined && isMobile) {
        menuItem.setIsMenuHovered();
      }
      if (filtersCTX) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          availabiltyFilters: {
            ...prevState.availabiltyFilters,
            DateStart: moment(dataFactoryCTX.PMSSettings?.DatePlanning).toDate(),
            DateEnd: moment(dataFactoryCTX.PMSSettings?.DatePlanning).add(1, 'day').toDate(),
          },
        }));

        if (subFormCTX.subForm.listOfSubformsDisplayed.find((e) => e.id === 666)) {
          return;
        }
        const newSubForm = {
          id: 666,
          component: LibreForm,
          data: {
            startDate: moment(dataFactoryCTX.PMSSettings?.DatePlanning).toDate(),
            endDate: moment(dataFactoryCTX.PMSSettings?.DatePlanning).add(1, 'day').toDate(),
          },
          actions: {
            hideSubForm: () => {
              subFormCTX.hideSubForm(666);
              Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
                element.className = '';
              });
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  DateStart: null,
                  DateEnd: null,
                  hasBeenModified: false,
                  needReload: false,
                  ColoredDates: [],
                },
              }));
            },
          },
          showBlackdiv: true,
          width: isMobile
            ? isTablet
              ? isPortrait
                ? '85vw'
                : 'auto'
              : isPortrait //phone
                ? '100vw'
                : 'auto'
            : 'auto',
          draggable: true,
          fullWidth: false,
          fullHeight: isMobileOnly ? true : false,
          translatedTitle: 'Disponibilité par type',
          x: 'calc(50% - 400px)',
          y: isMobileOnly ? 0 : '20vh',
        };

        subFormCTX.setSubForm((prevSubForm) => ({
          ...prevSubForm,
          listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
        }));
      }
      return;
    }
    if (menuItem.type === 'installation') {
      if (menuItem.setIsMenuHovered !== undefined && isMobile) {
        menuItem.setIsMenuHovered();
      }
      if (menuItem.level !== undefined) {
        navigate(
          'installation/' +
            menuItem.PermissionId +
            '/' +
            menuItem.CanAccess +
            '/' +
            menuItem.code +
            '/' +
            menuItem.level,
        );
      } else {
        navigate('installation/' + menuItem.PermissionId + '/' + menuItem.CanAccess + '/' + menuItem.code);
      }

      return;
    }
    navigate(menuItem.path ? menuItem.path : '/');
    if (menuItem.setIsMenuHovered !== undefined && isMobile) {
      menuItem.setIsMenuHovered();
    }
  };

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        if (item.path?.includes(url.pathname)) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (menuItem.path === 'planning' && menuItem.name === t('menusidebar.label.reservation')) {
      setIsMainActive(true);
    } else if (menuItem.path === url.pathname) {
      setIsMainActive(true);
    }
  };

  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    } else {
      setIsMenuExtended(true);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    setIsExpandable(Boolean(menuItem && menuItem.children && menuItem.children.length > 0));
  }, [menuItem]);

  return (
    <li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${isMainActive || isOneOfChildrenActive ? ' active' : ''}`}
        role="link"
        onClick={handleMainMenuAction}
        style={{ cursor: 'pointer' }}
      >
        <i className={`${menuItem.icon}`} />
        <span className="menu-item-text">{t(menuItem.name)}</span>
        {isExpandable ? <i className="right fas fa-angle-left" /> : null}
      </a>

      {isExpandable && menuItem.children && (
        <ul className="nav nav-treeview">
          {menuItem.children.map((childItem) => (
            <MenuItem key={childItem.name} menuItem={childItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
