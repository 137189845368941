import { ColumnMeta } from '@app/types/types';
import { exportToExcel } from '@app/utils/export';
import { Button } from 'primereact/button';
import React from 'react';

interface Props {
  exportedColumns: ColumnMeta[];
  data: any[];
  filename: string;
  datestart: string;
  dateend: string;
}

const ExportExcel: React.FC<Props> = (props) => {
  return (
    <>
      <Button
        style={{ width: '18px', height: '24px' }}
        type="button"
        icon="pi pi-file-excel"
        severity="success"
        rounded
        onClick={() => exportToExcel(props.data, props.exportedColumns, props.filename, props.datestart, props.dateend)}
        data-pr-tooltip="XLS"
      />
    </>
  );
};

export default ExportExcel;
