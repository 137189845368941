import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Room } from '@app/types/Hotel/room';
import { InstallationsMenu, Message, Permission } from '@app/types/types';
import { config } from '@app/utils/config';
import axiosNoAuth, { AxiosError, InternalAxiosRequestConfig } from 'axios'; // non surchargé
import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

import { storage } from '@store/localstorage/localStorage';

// Define the type for your params
export type UserType = {
  user?: User | null;
  language?: string | '';
  token?: string | null;
  ExpiresUtcAt?: string | null;
  error?: string | null;
  listHotel?: HotelRight[] | null;
  listTechHotel?: HotelRight[] | null;
  selectedHotel?: HotelRight | null;
  roomOfSelectedHotel?: Room[] | null;
  axiosIdProperty?: number;
  wsState?: WebSocket | null;
  wsToTreat: WsToTreat | null;
  refreshIntervalNumber?: number | null;
  currentAxiosRule: number | null;
  userMenu: InstallationsMenu[] | null;
  connectionID: string | null;
  permissions?: Permission[] | null;
};

// Define the interface for ParamsContextState
interface UserContextState {
  authenticationInfos: UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
  logout: () => void;
  getAllHotel: () => void;
  getRoomsByIdHotel: (IdHotel: number) => void;
  logonAPI: (params: AuthParams) => Promise<AuthData | undefined | Error>;
  refreshToken: () => void;
  connectGroubWebPubSub: (IdHotel: number) => Promise<string>;
  getHotelVisibility: (IdHotel: number, IdUser: string, isTech: boolean) => Promise<HotelRight[] | undefined | Error>;
  SendToGroup: (message: string, user: UserType) => void;
  getInstallationMenu: (id?: number) => Promise<InstallationsMenu[] | Error | undefined>;
  RedirectToInstallation: (eventId: string, level: string) => Promise<string | Error | undefined>;
  Impersonate: (params: AuthParams) => Promise<AuthData | undefined | Error>;
  forceLoading: boolean;
  setUserAsync: (value: React.SetStateAction<UserType>) => Promise<void>;
  getUserPermission: (IdHotel: number, IdUser: string) => Promise<Permission[]>;
  setForceLoading: (value: boolean) => Promise<void>;
  sendToUser: (userId: string, message: string) => void;
  messageList: Message[] | null;
  setMessageList: React.Dispatch<React.SetStateAction<Message[] | null>>;
}

// Create a context to hold your params with default values
export const UserContext = createContext<UserContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type UserProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const UserProvider: React.FC<UserProviderProps> = (props) => {
  const [authenticationInfos, setUserState] = useState<UserType>({
    user: null,
    language: '',
    token: null,
    ExpiresUtcAt: null,
    error: null,
    listHotel: null,
    listTechHotel: null,
    selectedHotel: null,
    roomOfSelectedHotel: null,
    wsState: null,
    wsToTreat: null,
    refreshIntervalNumber: null,
    currentAxiosRule: null,
    userMenu: null,
    connectionID: null,
    permissions: null,
  });
  const hotelIdRef = useRef<number | undefined>(undefined);

  const [forceLoading, setForceLoadingState] = useState(false);
  const [messageList, setMessageList] = useState<Message[] | null>(null);
  useEffect(() => {
    const selectedHotelId = authenticationInfos.selectedHotel?.IdHotel;

    // Mettre à jour la valeur du useRef à chaque changement
    hotelIdRef.current = selectedHotelId;

    if (authenticationInfos.currentAxiosRule) {
      axios.interceptors.request.eject(authenticationInfos.currentAxiosRule);
    }

    if (selectedHotelId) {
      const currentRule = axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
          const hotelId = hotelIdRef.current; // Utilisation de la valeur du useRef
          config.headers['IdProperty'] = hotelId;
          axios.defaults.headers.common['IdProperty'] = hotelId;
          storage.removeParam('axiosIdProperty');
          storage.setParam('axiosIdProperty', hotelId?.toString() ?? '');
          return config;
        },
        (error: AxiosError) => {
          getLogger('api').error(error);
          return Promise.reject(error);
        },
      );

      setUser((prevState) => ({
        ...prevState,
        axiosIdProperty: selectedHotelId,
        currentAxiosRule: currentRule,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationInfos.selectedHotel?.IdHotel]);
  useEffect(() => {
    if (authenticationInfos.currentAxiosRule) axios.interceptors.request.eject(authenticationInfos.currentAxiosRule);
    if (authenticationInfos.selectedHotel?.IdHotel) {
      axios.defaults.headers.common['IdProperty'] = authenticationInfos.selectedHotel?.IdHotel;
      const hotel = authenticationInfos.selectedHotel?.IdHotel;
      let currentRule;
      currentRule = axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
          config.headers['IdProperty'] = hotel;
          axios.defaults.headers.common['IdProperty'] = hotel;
          storage.removeParam('axiosIdProperty');
          storage.setParam('axiosIdProperty', hotel.toString() ?? '');
          return config;
        },
        (error: AxiosError) => {
          // Handle request error here
          getLogger('api').error(error);
          return Promise.reject(error);
        },
      );

      setUser((prevState) => ({
        ...prevState,

        axiosIdProperty: hotel,
        currentAxiosRule: currentRule,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationInfos.selectedHotel?.IdHotel]);

  const sendToUser = async (userId: string, message: string) => {
    const params = { UserId: userId, message: message, senderId: authenticationInfos.user?.Id };
    const response = await axios.post('/Socket/SendToUser', params, {});
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data = await response.data;

    return data;
  };
  const setForceLoading = async (bool: boolean) => {
    await setForceLoadingState(bool);
  };
  const logout = async () => {
    axios
      .post(config.API_URL + '/User/Logout', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .finally(() => {
        storage.removeParam('user');
        storage.removeParam('location');
        storage.removeParam('selectedHotel');
        storage.removeParam('hotelList');

        storage.removeParam('lastCall');
        storage.removeParam('access_token');

        storage.removeParam('ExpiresUtcAt');
        storage.removeParam('needToRelog');
        if (authenticationInfos.refreshIntervalNumber) clearInterval(authenticationInfos.refreshIntervalNumber);

        setUserState({
          user: null,
          language: '',
          token: null,
          ExpiresUtcAt: null,
          error: null,
          listHotel: null,
          selectedHotel: null,
          wsState: null,
          wsToTreat: null,
          listTechHotel: null,
          userMenu: null,
          currentAxiosRule: null,
          connectionID: null,
          permissions: null,
        });
      });
  };
  const SendToGroup = async (message: string, user: UserType) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const params = { IdGroup: user.selectedHotel?.IdHotel.toString(), Message: message };
      const urlToPass = 'SendToGroup';
      const response = await axios.post('/WebPubSub/' + urlToPass, params);
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      const data: MedialogResponse = await response.data;
      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getInstallationMenu = async (id?: number) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const response = await axios.get(config.API_URL + '/PmsData/Installations/menu/' + (id !== undefined ? id : ''));

      if (response.status !== 200) {
        return new Error('Erreur : ');
      }
      if (response.data.Success === false) {
        return new Error('Erreur : ' + response.data.ErrorCode);
      }
      const data: MedialogResponse = (await response.data) as MedialogResponse;
      if (data.Success === true) {
        const installationMenu: InstallationsMenu[] = data.Data as InstallationsMenu[];
        setUser((prevState) => ({
          ...prevState,
          userMenu: installationMenu,
        }));
        return installationMenu;
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const RedirectToInstallation = async (eventId: string, level: string) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        config.API_URL + '/PmsData/Installations/' + eventId + (level !== '' ? '/' + level : ''),
      );

      if (response.status !== 200) {
        return new Error('Erreur : ');
      }
      if (response.data.Success === false) {
        return new Error('Erreur : ' + response.data.ErrorCode);
      }
      const data: MedialogResponse = (await response.data) as MedialogResponse;
      if (data.Success === true) {
        const installationMenu: string = data.Data as unknown as string;
        return installationMenu;
      }
    } catch (error) {
      getLogger('web').error(error);
    }
    return '';
  };

  const Impersonate = async (params: AuthParams) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const response = await axios.post(config.API_URL + '/User/Impersonate', params, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        return new Error('Erreur : ');
      }
      if (response.data.Success === false) {
        return new Error('Erreur : ' + response.data.ErrorCode);
      }
      const data: MedialogResponse = (await response.data) as MedialogResponse;

      return data.Data as AuthData;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const logonAPI = async (params: AuthParams) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const response = await axiosNoAuth.post(config.API_URL + '/User/Authentify', params, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status !== 200) {
        return new Error('Erreur : ');
      }
      if (response.data.Success === false) {
        return new Error('Erreur : ' + response.data.ErrorCode);
      }
      const data: MedialogResponse = (await response.data) as MedialogResponse;
      if (data.Success === true) {
        const LoggedData: AuthData = data.Data as AuthData;
        storage.setParam('access_token', LoggedData.Token);
        storage.setParam('ExpiresUtcAt', LoggedData.ExpiresUtcAt);
      }
      return data.Data as AuthData;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getHotelVisibility = async (IdHotel: number, IdUser: string, isTech: boolean) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const response = await axios.post(
        '/hotel/UserVisibility',
        { IdHotel, IdUser },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status !== 200) {
        return new Error('Erreur : ');
      }
      if (response.data.Success === false) {
        return new Error('Erreur : ' + response.data.ErrorCode);
      }
      const data: MedialogResponse = (await response.data) as MedialogResponse;
      if (data.Success === true) {
        if (isTech) {
          setUser((prevState) => ({
            ...prevState,
            listTechHotel: data.Data as HotelRight[],
          }));
        } else {
          storage.setParam('hotelList', JSON.stringify(data.Data));

          setUser((prevState) => ({
            ...prevState,
            listHotel: data.Data as HotelRight[],
          }));
          const listHotel = data.Data as HotelRight[];
          if (listHotel.length === 1 || listHotel.filter((e) => e.PmsAccess === true).length === 1) {
            setUser((prevState) => ({
              ...prevState,
              selectedHotel: listHotel.filter((e) => e.PmsAccess === true)[0],
            }));
            storage.setParam('selectedHotel', JSON.stringify(listHotel.filter((e) => e.PmsAccess === true)[0]));
          }
        }
      }
      return data.Data as HotelRight[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const refreshToken = async () => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const response = await axios.post('/User/RefreshToken');
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = (await response.data) as MedialogResponse;
      if (data.Success === true) {
        const LoggedData: AuthData = data.Data as AuthData;
        storage.setParam('access_token', LoggedData.Token);
        storage.setParam('ExpiresUtcAt', LoggedData.ExpiresUtcAt);
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getAllHotel = async () => {
    try {
      const response = await axios.get('/Hotel/all');

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setUser((prevState) => ({
        ...prevState,
        listHotel: data.Data as HotelRight[],
      }));
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getRoomsByIdHotel = async (IdHotel: number) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const urlToPass = 'Rooms/';
      const response = await axios.get('/PmsData/' + urlToPass + IdHotel + '/true');

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setUser((prevState) => ({
        ...prevState,
        roomOfSelectedHotel: data.Data as Room[],
      }));
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const connectGroubWebPubSub = async (IdHotel: number) => {
    const response = await axios.get('/WebPubSub/AddUserToWsGroup?IdHotel=' + IdHotel, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data: string = (await response.data) as string;

    return data;
  };
  const resolveRef = useRef<() => void>();

  const setUserAsync = useCallback((value: React.SetStateAction<UserType>) => {
    if (typeof value === 'function') {
      // Si value est une fonction, log la fonction (optionnel)
    } else {
      // Si value est un objet de type UserType, log cet objet
      storage.setParam('user', JSON.stringify(value.user));
    }
    return new Promise<void>((resolve) => {
      resolveRef.current = resolve;
      setUserState((prevState) => {
        const newState = typeof value === 'function' ? value(prevState) : value;
        return newState;
      });
    });
  }, []);

  const getUserPermission = async (IdHotel: number, IdUser: string) => {
    const response = await axios.get('/User/Permissions/' + IdHotel + '/' + IdUser, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data = (await response.data) as MedialogResponse;
    setUser((prevState) => ({
      ...prevState,
      permissions: data.Data as Permission[],
    }));
    return data.Data as Permission[];
  };

  useEffect(() => {
    if (resolveRef.current) {
      resolveRef.current();
      resolveRef.current = undefined;
    }
  }, [authenticationInfos]);

  const setUser: React.Dispatch<React.SetStateAction<UserType>> = (value) => {
    // Ajouter des logs pour le débogage
    if (typeof value === 'function') {
      // Si value est une fonction, log la fonction (optionnel)
    } else {
      // Si value est un objet de type UserType, log cet objet
      storage.setParam('user', JSON.stringify(value.user));
    }
    // Appel de la fonction setUserState
    setUserState(value);
  };
  return (
    <UserContext.Provider
      value={{
        authenticationInfos,
        getUserPermission,
        setUser,
        logout,
        getAllHotel,
        getHotelVisibility,
        getRoomsByIdHotel,
        logonAPI,
        refreshToken,
        connectGroubWebPubSub,
        SendToGroup,
        getInstallationMenu,
        RedirectToInstallation,
        Impersonate,
        setForceLoading,
        setUserAsync,
        forceLoading,
        setMessageList,
        sendToUser,
        messageList,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

// Create a custom hook to access the params
export function useUser(): UserContextState {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return userContext;
}
