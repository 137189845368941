import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment-timezone';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useWindow } from '@app/modules/WindowContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';

const PlanningFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const filtersCTX = useFilters();

  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const windowCTX = useWindow();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const [t] = useTranslation();
  const authData = userCTX.authenticationInfos;
  const rooms = userCTX.authenticationInfos.roomOfSelectedHotel;
  const [typeMode, setTypeMode] = useState(0);
  const [isFirstCall, setIsFirstCall] = useState(true);
  const dataFactoryCTX = useDataFactory();
  const displayOptions = [
    { title: t('labels.rooms'), value: 0 },
    { title: t('labels.type'), value: 1 },
    { icon: 'fa-solid fa-minimize', value: 6 },

    { title: t('labels.day'), value: 3 },
    { title: t('labels.point'), value: 4 },
  ];
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const iconTemplate = (option: SelectItem) => {
    if (option.icon) {
      return <i className={option.icon.toString()}></i>;
    } else {
      return option.title;
    }
  };
  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  useEffect(() => {
    if (filtersCTX && filtersCTX.filters.planningFilters && isFirstCall && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          DateStart: moment(selectedHotel?.CurrentPlanningDate).toDate().getTime(),
          DateEnd: moment(selectedHotel?.CurrentPlanningDate).add(21, 'day').toDate().getTime(),
          SelectedHotels: selectedHotel,
          IdHotel: selectedHotel.IdHotel,
        },
      }));
      setIsFirstCall(false);
    }
  }, [filtersCTX, filtersCTX.filters.planningFilters, isFirstCall, selectedHotel]);

  useEffect(() => {
    if (filtersCTX && filtersCTX.filters.planningFilters && isTech === true && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          SelectedHotels: selectedHotel,
          IdHotel: selectedHotel.IdHotel,
        },
      }));
      setIsFirstCall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTech, selectedHotel]);

  const handleChangePlanningDisplay = (value: number) => {
    const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 10000);
    if (form) {
      form.actions.hideSubForm();
    }
    if (
      value === null &&
      filtersCTX &&
      filtersCTX.filters &&
      filtersCTX.filters.planningFilters &&
      filtersCTX.filters.planningFilters.CurrentSort === 1
    ) {
      if (typeMode === 0) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          planningFilters: {
            ...prevState.planningFilters,
            CurrentSort: 2,
            ForceOneDay: false,
          },
        }));

        setTypeMode(1);
      } else {
        setTypeMode(0);
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          planningFilters: {
            ...prevState.planningFilters,
            CurrentSort: 1,
            ForceOneDay: false,
          },
        }));
      }
      return;
    } else {
      switch (value) {
        case 0:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 0,
              ForceOneDay: false,
            },
          }));
          break;
        case 1:
          setTypeMode(0);
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 1,
              ForceOneDay: false,
            },
          }));
          break;
        case 6:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: !prevState.planningFilters.IsCollapse,
            },
          }));
          break;
        case 3:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: false,
              ForceOneDay: true,
              CurrentSort: 3,
            },
          }));
          break;
        case 4:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              ForceOneDay: true,
              IsCollapse: false,
              CurrentSort: 4,
            },
          }));
          break;
      }
    }
    storage.setParam('CurrentSort', value.toString());
  };
  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '4px',

              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };
  const dateTemplate = (event: CalendarDateTemplateEvent) => {
    const currentDate: Date = new Date(event.year, event.month, event.day);
    if (
      filtersCTX.filters.planningFilters?.DateStart &&
      (currentDate < filtersCTX.filters.planningFilters?.DateStart ||
        currentDate >
          moment(new Date(filtersCTX.filters.planningFilters?.DateStart))
            .add(filtersCTX.filters.planningFilters.dayToDisplay, 'day')
            .toDate())
    ) {
      return <strong style={{ textDecoration: 'line-through', opacity: '0.2', color: 'red' }}>{event.day}</strong>;
    }
    return event.day; // default behavior
  };
  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isMobile &&
        isLocaleSet &&
        filtersCTX.filters.planningFilters.CurrentSort !== null &&
        filtersCTX.filters.planningFilters && (
          <Accordion activeIndex={0}>
            <AccordionTab header={t('labels.filters')} tabIndex={0}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'transparent',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  color: colors.colorGreyAdminLTE,
                  padding: '10px',
                }}
              >
                <div style={{ width: '100%', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '50px',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <label style={{ margin: 0 }}> {t('header.label.start')}</label>
                    {filtersCTX.filters.planningFilters.CurrentSort === 4 && (
                      <Calendar
                        value={moment(filtersCTX.filters.planningFilters?.DatePointStart).toDate()}
                        todayButtonClassName={'hidden'}
                        style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          filtersCTX.setFilters((prevState) => ({
                            ...prevState,
                            planningFilters: {
                              ...prevState.planningFilters,
                              hasBeenModified: true,

                              DatePointStart: moment(e.value?.getTime()).toDate(),
                              DatePointEnd: moment(e.value?.getTime()).add(1, 'day').toDate(),
                            },
                          }))
                        }
                        locale={authData?.language || 'fr'}
                      />
                    )}
                    {filtersCTX.filters.planningFilters.CurrentSort != 4 && (
                      <Calendar
                        value={new Date(filtersCTX.filters.planningFilters?.DateStart)}
                        todayButtonClassName={'hidden'}
                        style={{ width: '70%' }}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          filtersCTX.setFilters((prevState) => ({
                            ...prevState,
                            planningFilters: {
                              ...prevState.planningFilters,
                              DateStart: moment(e.value?.getTime()).toDate(),
                              DateEnd: moment(e.value)
                                .add(filtersCTX.filters.planningFilters.dayToDisplay, 'day')
                                .toDate(),
                              hasBeenModified: true,
                            },
                          }))
                        }
                        locale={authData?.language || 'fr'}
                      />
                    )}
                  </div>
                  {filtersCTX.filters.planningFilters.CurrentSort !== 4 &&
                    filtersCTX.filters.planningFilters.CurrentSort !== 3 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: '50px',
                          flexWrap: 'wrap',
                          alignContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'row',
                          minHeight: '50px',
                        }}
                      >
                        <label style={{ margin: 0 }}> {t('header.label.see')} </label>
                        <Dropdown
                          options={[7, 14, 21, 28]}
                          value={filtersCTX.filters.planningFilters.dayToDisplay}
                          onChange={(e) =>
                            filtersCTX.setFilters((prevState) => ({
                              ...prevState,
                              planningFilters: {
                                ...prevState.planningFilters,
                                dayToDisplay: e.value,
                                hasBeenModified: true,

                                DateEnd: moment(filtersCTX.filters.planningFilters.DateStart)
                                  .add(e.value, 'day')
                                  .format('YYYY-MM-DD'),
                              },
                            }))
                          }
                        ></Dropdown>
                      </div>
                    )}
                </div>
                <div style={{ width: '100%', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',

                      flexWrap: 'wrap',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    {filtersCTX.filters.planningFilters && rooms && (
                      <MultiSelect
                        panelHeaderTemplate={customHeader}
                        value={filtersCTX.filters.planningFilters?.TypeToDisplay}
                        onChange={(e) => {
                          filtersCTX.setFilters((prevState) => ({
                            ...prevState,
                            planningFilters: {
                              ...prevState.planningFilters,
                              TypeToDisplay: e.value,
                            },
                          }));
                        }}
                        options={rooms
                          .map((e) => e.RoomType)
                          .filter((value, index, self) => self.indexOf(value) === index)}
                        placeholder="Type de chambre"
                        maxSelectedLabels={
                          (dataFactoryCTX?.DataDictionnary?.Rooms?.map((e) =>
                            e.RoomType.replace('[VIRTUAL]', 'S/R'),
                          ).filter((value, index, self) => self.indexOf(value) === index).length ?? 0) - 1
                        }
                        selectedItemsLabel={'Tous'}
                        style={{ width: '90%' }}
                      />
                    )}
                  </div>

                  {isTech && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '50px',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Dropdown
                        value={selectedHotel}
                        onChange={async (e) => {
                          userCTX
                            .getHotelVisibility(
                              e.value.IdHotel as number,
                              userCTX.authenticationInfos.user?.Id as string,
                              isTech,
                            )
                            .then((res) => {
                              if (res) {
                                userCTX.setUser((prevState) => ({
                                  ...prevState,
                                  selectedHotel: e.value ?? '',
                                }));
                                storage.setParam('selectedHotel', JSON.stringify(e.value));
                              }
                            });

                          storage.setParam('selectedHotel', JSON.stringify(e.value));
                          filtersCTX.setFilters((prevState) => ({
                            ...prevState,
                            planningFilters: {
                              ...prevState.planningFilters,

                              hasBeenModified: true,
                            },
                          }));
                        }}
                        options={hotelList as HotelData[]}
                        optionLabel="Name"
                        filter
                        placeholder={t('label.placeholder.choice') ?? ''}
                        className="w-full md:w-14rem"
                        style={{ whiteSpace: 'nowrap', maxWidth: '90%' }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: '100%',
                    flexDirection: 'column',
                    display: 'flex',
                    minHeight: '50px',
                    paddingTop: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '50px',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <SelectButton
                      itemTemplate={iconTemplate}
                      value={filtersCTX.filters.planningFilters?.CurrentSort}
                      onChange={(e) => handleChangePlanningDisplay(e.value)}
                      optionLabel="na"
                      width={'100%'}
                      options={displayOptions}
                    />
                  </div>
                </div>
                <div style={{ width: '100%', flexDirection: 'column', display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      height: '50px',
                      flexWrap: 'wrap',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '4px',
                        backgroundColor: filtersCTX.filters.planningFilters.hasBeenModified
                          ? colors.colorSuccessMain
                          : darkMode
                            ? 'var(--color-primary-light)'
                            : 'var(--color-primary-main)',

                        textAlign: 'center',
                        justifyContent: 'center',
                      }}
                      icon="pi pi-refresh"
                      onClick={() => {
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          planningFilters: {
                            ...prevState.planningFilters,
                            needReload: true,
                            hasBeenModified: false,
                          },
                        }));
                      }}
                    ></Button>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',

                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                {' '}
              </div>
            </AccordionTab>
          </Accordion>
        )}
      {!isMobile &&
        isLocaleSet &&
        filtersCTX.filters.planningFilters.CurrentSort !== null &&
        filtersCTX.filters.planningFilters && (
          <>
            <SelectButton
              className="selectButton"
              itemTemplate={iconTemplate}
              value={filtersCTX.filters.planningFilters?.CurrentSort}
              onChange={(e) => handleChangePlanningDisplay(e.value)}
              optionLabel="name"
              options={displayOptions}
              style={{ marginRight: '20px', borderRadius: 0 }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {filtersCTX.filters.planningFilters.CurrentSort === 4 && (
                <Calendar
                  value={moment(filtersCTX.filters.planningFilters?.DatePointStart).toDate()}
                  todayButtonClassName={'hidden'}
                  style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                  dateFormat="dd/mm/yy"
                  onChange={(e) =>
                    filtersCTX.setFilters((prevState) => ({
                      ...prevState,
                      planningFilters: {
                        ...prevState.planningFilters,
                        hasBeenModified: true,

                        DatePointStart: moment(e.value?.getTime()).toDate(),
                        DatePointEnd: moment(e.value?.getTime()).add(1, 'day').toDate(),
                      },
                    }))
                  }
                  locale={authData?.language || 'fr'}
                />
              )}
              {filtersCTX.filters.planningFilters.CurrentSort != 4 && (
                <Calendar
                  value={new Date(filtersCTX.filters.planningFilters?.DateStart)}
                  todayButtonClassName={'hidden'}
                  style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                  dateFormat="dd/mm/yy"
                  onChange={(e) =>
                    filtersCTX.setFilters((prevState) => ({
                      ...prevState,
                      planningFilters: {
                        ...prevState.planningFilters,
                        hasBeenModified: true,

                        DateStart: moment(e.value?.getTime()).toDate(),
                        DateEnd: moment(e.value).add(prevState.planningFilters.dayToDisplay, 'day').toDate(),
                      },
                    }))
                  }
                  locale={authData?.language || 'fr'}
                />
              )}
            </div>
            {filtersCTX.filters.planningFilters.CurrentSort !== 4 &&
              filtersCTX.filters.planningFilters.CurrentSort !== 3 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',

                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.see')} </label>
                  <Dropdown
                    options={[7, 14, 21, 28]}
                    value={filtersCTX.filters.planningFilters.dayToDisplay}
                    onChange={(e) =>
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        planningFilters: {
                          ...prevState.planningFilters,
                          dayToDisplay: e.value,
                          hasBeenModified: true,

                          DateEnd: moment(filtersCTX.filters.planningFilters.DateStart)
                            .add(e.value, 'day')
                            .format('YYYY-MM-DD'),
                        },
                      }))
                    }
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                  ></Dropdown>
                </div>
              )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              {filtersCTX.filters.planningFilters &&
                filtersCTX.filters.planningFilters.CurrentSort === 4 &&
                dataFactoryCTX.DataDictionnary.Rooms && (
                  <MultiSelect
                    panelHeaderTemplate={customHeader}
                    value={filtersCTX.filters.planningFilters?.TypeToDisplay}
                    onChange={(e) => {
                      /*  dispatch(
                setPlanningFilters({
                  ...filtersCTX.filters.planningFilters,
                  TypeToDisplay: e.value,
                }),
              );*/

                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        planningFilters: {
                          ...prevState.planningFilters,

                          TypeToDisplay: e.value,
                        },
                      }));
                    }}
                    maxSelectedLabels={
                      (dataFactoryCTX.DataDictionnary.Rooms.map((e) => e.RoomType.replace('[VIRTUAL]', 'S/R')).filter(
                        (value, index, self) => self.indexOf(value) === index,
                      ).length ?? 0) - 1
                    }
                    selectedItemsLabel={'Tous'}
                    options={dataFactoryCTX.DataDictionnary.Rooms.map((e) =>
                      e.RoomType.replace('[VIRTUAL]', 'S/R'),
                    ).filter((value, index, self) => self.indexOf(value) === index)}
                    placeholder="Type de chambre"
                    style={{ marginRight: '10px', marginLeft: '10px', width: '200px', maxHeight: '500px' }}
                    panelStyle={{ minHeight: '300px' }}
                  />
                )}
              <Button
                style={{
                  marginRight: '10px',
                  marginLeft: '10px',
                  borderRadius: '4px',
                  backgroundColor: filtersCTX.filters.planningFilters.hasBeenModified
                    ? colors.colorSuccessMain
                    : darkMode
                      ? 'var(--color-primary-light)'
                      : 'var(--color-primary-main)',

                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                icon="pi pi-refresh"
                onClick={() => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    planningFilters: {
                      ...prevState.planningFilters,
                      needReload: true,
                      hasBeenModified: false,
                    },
                  }));
                }}
              ></Button>
            </div>
          </>
        )}
    </div>
  );
};

export default PlanningFilters;
