import { ReactElement, useEffect, useState } from 'react';

import { useSubForm } from '@app/modules/SubFormContext';
import { SegmentsEntity } from '@app/types/dataFactory';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Availability, Rate } from '@app/types/types';
import { Button } from 'primereact/button';
import { useUser } from '@app/modules/user/UserContext';
import moment from 'moment-timezone';
import { Calendar } from 'primereact/calendar';
import { dateTo_DD_MM, getDeltaInDays } from '@app/utils/dates';
import { InputNumber } from 'primereact/inputnumber';
import colors from '@app/styles/colors';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { Dropdown } from 'primereact/dropdown';
import { formatCurrency, formatPrice } from '@app/utils/formatCurrency';
import { Tooltip } from '@mui/material';
import { formatDate } from '@mobiscroll/react';
import { useTranslation } from 'react-i18next';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { isMobileOnly } from 'react-device-detect';

interface props {
  data: any;
  title: ReactElement;
  showTitle: boolean;
  selectedId: string;
}

const LibreForm: React.FC<props> = ({ data, title }) => {
  const planningCTX = usePlanning();
  const filtersCTX = useFilters();
  const userCTX = useUser();
  const dataFactoryCTX = useDataFactory();
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const [selectedOccupancy, setSelectedOccupancy] = useState('');
  const [selectedOrder, setSelectedOrder] = useState({ name: 'Prix croissants', id: 0 });
  const [t] = useTranslation();
  const subFormCTX = useSubForm();
  const [selectedLine, setSelectedLine] = useState<Availability>();

  useEffect(() => {
    planningCTX.getFreeAvailabilities(data.startDate, data.endDate);
    return () => {
      if (subFormCTX) {
        subFormCTX.hideSubForm(666);
      }
      if (planningCTX) {
        planningCTX.setFreeAvailabilities(null);
        planningCTX.setRates(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (planningCTX.Rates && planningCTX.Rates.length > 0) {
      setSelectedOccupancy(planningCTX.Rates[0].Occupancy);
    }
  }, [planningCTX.Rates]);

  useEffect(() => {
    if (filtersCTX.filters.availabiltyFilters.needReload) {
      planningCTX.getFreeAvailabilities(
        filtersCTX.filters.availabiltyFilters.DateStart as Date,
        filtersCTX.filters.availabiltyFilters.DateEnd as Date,
      );

      if (selectedLine) {
        planningCTX.getRates(
          filtersCTX.filters.availabiltyFilters.DateStart as Date,
          filtersCTX.filters.availabiltyFilters.DateEnd as Date,
          selectedLine.IdRoomType,
        );
      }

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        availabiltyFilters: {
          ...prevState.availabiltyFilters,
          hasBeenModified: false,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.availabiltyFilters.needReload]);

  useEffect(() => {
    planningCTX.getFreeAvailabilities(
      filtersCTX.filters.availabiltyFilters.DateStart as Date,
      filtersCTX.filters.availabiltyFilters.DateEnd as Date,
    );

    if (selectedLine) {
      planningCTX.getRates(
        filtersCTX.filters.availabiltyFilters.DateStart as Date,
        filtersCTX.filters.availabiltyFilters.DateEnd as Date,
        selectedLine.IdRoomType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.availabiltyFilters.DateStart, filtersCTX.filters.availabiltyFilters.DateEnd]);
  const displayReservation = (e: any) => {
    return e.CanCreateReservation ? (
      <Button disabled style={{ width: '24px', height: '24px' }} icon="fa-solid fa-circle-plus"></Button>
    ) : (
      <></>
    );
  };

  const displayRate = (e: any) => {
    return e.CanDisplayRate ? (
      <Button
        onClick={() => {
          setSelectedLine(e);
          planningCTX.getRates(
            filtersCTX.filters.availabiltyFilters.DateStart as Date,
            filtersCTX.filters.availabiltyFilters.DateEnd as Date,
            e.IdRoomType,
          );
        }}
        style={{ width: '24px', height: '24px' }}
        icon="fa-solid fa-sliders fa-rotate-90"
      ></Button>
    ) : (
      <></>
    );
  };

  const displayType = (e: any) => {
    return (
      <div
        style={{ fontWeight: e.Bold ? 'bold' : 'normal', color: e.Color ? e.Color : 'black', padding: '5px' }}
        title={e?.RoomTypeDescription}
      >
        {e.TypeName.indexOf('[') >= 0 ? t('labels.' + e.TypeName) : e.TypeName}
      </div>
    );
  };
  const sortItems = (items: Rate[]) => {
    return items.sort((a, b) => {
      // Tri par CanUseIt d'abord (true avant false)
      if (a.Product.CanUseIt !== b.Product.CanUseIt) {
        return a.Product.CanUseIt ? -1 : 1; // true avant false
      }

      // Ensuite, tri par ordre de prix croissant
      switch (selectedOrder.id) {
        case 0: // Prix croissants
          return a.Product.StayPrice - b.Product.StayPrice;
        case 1: // Prix décroissants
          return b.Product.StayPrice - a.Product.StayPrice;
        case 2: // Code Tarif
          return a.RateCode.localeCompare(b.RateCode);
        case 3: // Produits
          return a.Product.ProductLibelle.localeCompare(b.Product.ProductLibelle);
        default:
          return 0;
      }
    });
  };
  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = userCTX.authenticationInfos?.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };

  return (
    <div style={{ padding: '10px', backgroundColor: '#333' }}>
      <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', backgroundColor: 'white' }}>
        <DataTable
          rowHover
          selection={selectedLine}
          style={{ padding: 0, width: isMobileOnly ? '90vw' : '20vw', minWidth: isMobileOnly ? '90vw' : '400px' }}
          stripedRows
          selectionMode={'single'}
          onSelectionChange={(e) => setSelectedLine(e.value as Availability)}
          className="normalDT previsionDT"
          value={planningCTX.FreeAvailabilities as Availability[]}
        >
          <Column
            style={{ marginLeft: '20px', textAlign: 'left', justifyContent: 'flex-start' }}
            body={(e) => displayType(e)}
          ></Column>
          <Column body={(e) => <div style={{ padding: '5px' }}>{e.Quantity}</div>}></Column>
          <Column body={(e) => displayReservation(e)}></Column>
          <Column body={(e) => displayRate(e)}></Column>
        </DataTable>
        {planningCTX.Rates && (
          <div
            style={{
              width: isMobileOnly ? '90vw' : '30vw',
              minWidth: isMobileOnly ? '90vw' : '500px',
              borderLeft: '4px solid #333',
              padding: '5px',
              backgroundColor: '#f0f0fe',
            }}
          >
            {planningCTX.Rates.length == 0 && (
              <div style={{ width: '100%', textAlign: 'center' }}>Il n’y a pas de tarif disponible pour la période</div>
            )}
            {planningCTX.Rates.length > 0 && (
              <>
                <div style={{ padding: '10px' }}>
                  {planningCTX.Rates.map((e) => e.Occupancy).length > 1 && (
                    <div
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',

                        justifyContent: 'space-between',
                      }}
                    >
                      <Dropdown
                        style={{ width: '100%' }}
                        value={selectedOccupancy}
                        options={planningCTX.Rates.map((e) => e.Occupancy)}
                        onChange={(e) => setSelectedOccupancy(e.value)}
                      ></Dropdown>
                    </div>
                  )}
                  {planningCTX?.Rates?.find((e) => e.Occupancy === selectedOccupancy)?.Items != undefined &&
                    (planningCTX?.Rates?.find((e) => e.Occupancy === selectedOccupancy)?.Items?.length ?? 0) > 1 && (
                      <div
                        style={{
                          paddingTop: '10px',
                          width: '100%',
                          flexDirection: 'row',
                          display: 'flex',
                          alignItems: 'center',

                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ width: '100px' }}>Trié par :</div>
                        <Dropdown
                          style={{ width: '85%' }}
                          value={selectedOrder}
                          optionLabel="name"
                          onChange={(e) => setSelectedOrder(e.value)}
                          defaultValue={0}
                          options={[
                            { name: 'Prix croissants', id: 0 },
                            { name: 'Prix décroissants', id: 1 },
                            { name: 'Code Tarif', id: 2 },
                            { name: 'Produits', id: 3 },
                          ]}
                        ></Dropdown>
                      </div>
                    )}
                </div>
                <div style={{ maxHeight: '375px', overflow: 'auto', backgroundColor: '#f0f0fe' }}>
                  {planningCTX?.Rates?.find((e) => e.Occupancy === selectedOccupancy)?.Items &&
                    sortItems(planningCTX?.Rates?.find((e) => e.Occupancy === selectedOccupancy)?.Items as Rate[]).map(
                      (item: Rate) => {
                        return (
                          <div
                            style={{
                              padding: '10px',
                              backgroundColor: '#fff',
                              margin: '10px',
                              border: '1px solid #999',
                            }}
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Tooltip
                                title={
                                  <div>
                                    {Object.entries(item.Product.DayPrices).map(([date, price]) => (
                                      <div key={date}>
                                        {formatDate('DDD DD/MM/YY', new Date(date), {
                                          dayNamesShort: Object.values(
                                            t('calendar.dayNamesShort', { returnObjects: true }),
                                          ),
                                        })}
                                        : {formatPrice(price, dataFactoryCTX?.PMSSettings?.DefaultCurrency ?? null)}
                                      </div>
                                    ))}
                                    <div>
                                      Prix moyen :{' '}
                                      {formatPrice(
                                        item.Product.AveragePrice,
                                        dataFactoryCTX?.PMSSettings?.DefaultCurrency ?? null,
                                      )}
                                    </div>
                                  </div>
                                }
                              >
                                <div style={{ width: '45%' }}> {item.Product.ProductLibelle}</div>
                              </Tooltip>
                              <div>
                                <Tooltip title={item.RateDescription}>
                                  <b
                                    style={{
                                      fontSize: '0.8rem',
                                      maxWidth: '100px',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: '100px',
                                      display: 'block',
                                    }}
                                  >
                                    {item.RateCode}
                                  </b>
                                </Tooltip>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {item.SalesCondition && (
                                  <Tooltip title={item.SalesCondition}>
                                    <i style={{ margin: '5px' }} className="fa-solid fa-circle-exclamation"></i>
                                  </Tooltip>
                                )}
                                <Button disabled={!item.Product.CanUseIt}>
                                  {formatCurrency(item.Product.StayPrice * 100)}
                                </Button>
                              </div>
                            </div>
                            {item.Product.Infos.map((e) =>
                              e.Detail ? (
                                <Tooltip title={e.Detail}>
                                  <div style={{ color: e.IsWarning ? 'red' : '' }}>{e.Info}</div>
                                </Tooltip>
                              ) : (
                                <div style={{ color: e.IsWarning ? 'red' : '' }}>{e.Info}</div>
                              ),
                            )}
                            {item.OtherProducts.length > 0 && (
                              <div style={{ margin: '10px' }}>
                                <Accordion>
                                  <AccordionTab header="Autre produits">
                                    {item.OtherProducts.map((p) => (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Tooltip
                                          title={
                                            <div>
                                              {Object.entries(p.DayPrices).map(([date, price]) => (
                                                <div key={date}>
                                                  {formatDate('DDD DD/MM/YY', new Date(date), {
                                                    dayNamesShort: Object.values(
                                                      t('calendar.dayNamesShort', { returnObjects: true }),
                                                    ),
                                                  })}
                                                  :{' '}
                                                  {formatPrice(
                                                    price,
                                                    dataFactoryCTX?.PMSSettings?.DefaultCurrency ?? null,
                                                  )}
                                                </div>
                                              ))}
                                              <div>
                                                Prix moyen :{' '}
                                                {formatPrice(
                                                  p.AveragePrice,
                                                  dataFactoryCTX?.PMSSettings?.DefaultCurrency ?? null,
                                                )}
                                              </div>
                                            </div>
                                          }
                                        >
                                          <div style={{ width: '45%' }}> {p.ProductLibelle}</div>
                                        </Tooltip>

                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Button>{formatCurrency(p.StayPrice * 100)}</Button>
                                        </div>
                                      </div>
                                    ))}
                                  </AccordionTab>
                                </Accordion>
                              </div>
                            )}
                          </div>
                        );
                      },
                    )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          borderTop: '4px solid #333',
          backgroundColor: 'silver',
          padding: '10px',
        }}
      >
        <div>
          <i className="fa-solid fa-plane-arrival" style={{ color: '#1f512e' }}></i>
          <Calendar
            minDate={moment(userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate).toDate()}
            value={filtersCTX?.filters?.availabiltyFilters?.DateStart as Date}
            inputStyle={{ width: '80px' }}
            dateFormat={getDateFormat(filtersCTX?.filters?.availabiltyFilters?.DateStart as Date)}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onChange={(e) => {
              const currentNights = getDeltaInDays(
                filtersCTX?.filters?.availabiltyFilters?.DateStart as Date,
                filtersCTX?.filters?.availabiltyFilters?.DateEnd as Date,
              );
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  ...prevState.availabiltyFilters,
                  DateStart: e.value as Date,
                  DateEnd: moment(e.value).add(currentNights, 'days').toDate(),

                  hasBeenModified: true,
                },
              }));
            }}
            // maxDate={moment(new Date(dashboardFilters.DateEnd)).toDate()}
            locale={userCTX?.authenticationInfos.language || 'fr'}
          />
        </div>
        <div>
          <i style={{ color: 'darkgoldenrod', marginRight: '5px' }} className="fa-solid fa-moon"></i>
          <InputNumber
            showButtons
            buttonLayout="horizontal"
            inputStyle={{ width: '60px', textAlign: 'right' }}
            min={0}
            placeholder="Nombre de nuits"
            value={getDeltaInDays(
              filtersCTX?.filters?.availabiltyFilters?.DateStart as Date,
              filtersCTX?.filters?.availabiltyFilters?.DateEnd as Date,
            )}
            onValueChange={(e) =>
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  ...prevState.availabiltyFilters,
                  DateEnd: moment(filtersCTX?.filters?.availabiltyFilters?.DateStart)
                    .add('days', e.value as number)
                    .toDate(),
                  hasBeenModified: true,
                },
              }))
            }
          ></InputNumber>
        </div>
        <div>
          <i className="fa-solid fa-plane-departure" style={{ color: 'darkred' }}></i>
          <Calendar
            minDate={moment(filtersCTX?.filters?.availabiltyFilters?.DateStart).add(1, 'day').toDate()}
            value={filtersCTX?.filters?.availabiltyFilters?.DateEnd as Date}
            inputStyle={{ width: '80px' }}
            dateFormat={getDateFormat(filtersCTX?.filters?.availabiltyFilters?.DateEnd as Date)}
            style={{ marginLeft: '5px', marginRight: '5px' }}
            onChange={(e) => {
              const currentNights = getDeltaInDays(
                filtersCTX?.filters?.availabiltyFilters?.DateStart as Date,
                filtersCTX?.filters?.availabiltyFilters?.DateEnd as Date,
              );

              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  ...prevState.availabiltyFilters,
                  DateEnd: e.value as Date,
                  hasBeenModified: true,
                },
              }));
            }}
            // maxDate={moment(new Date(dashboardFilters.DateEnd)).toDate()}
            locale={userCTX?.authenticationInfos.language || 'fr'}
          />
        </div>
      </div>
    </div>
  );
};

export default LibreForm;
