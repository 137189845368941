import React from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import MessageList from './MessageList';
import NewMessage from './NewMessage';

interface messageList {
  sender: string;
  recipient: string;
  message: string;
  date: string;
}

interface props {
  data: any;
}

const Message: React.FC<props> = (props) => {
  const userCTX = useUser();
  return (
    <div>
      <MessageList recipient={props.data.recipient}></MessageList>
      <NewMessage recipient={props.data.recipient}></NewMessage>
    </div>
  );
};

export default Message;
