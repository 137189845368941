import React from 'react';

import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useSubForm } from '@app/modules/SubFormContext';
import colors from '@app/styles/colors';
import { Room } from '@app/types/Hotel/room';
import { ContextMenu } from 'primereact/contextmenu';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import { hexToRgba } from '@app/utils/themes';

const CustomResourceBreakingType = ({ resource }: { resource: Room }) => {
  const [t] = useTranslation();
  const parent = resource.children;
  const subFormCTX = useSubForm();
  const planningCTX = usePlanning();
  const cm = subFormCTX.cmResourceRef;
  const items = [
    { label: "Changer le statut de cette chambre en 'sale'", icon: 'fa-solid fa-broom' },
    { label: "Changer le statut des chambres en 'sale'", icon: 'fa-solid fa-broom' },
    { label: "Changer le statut de cette chambre en 'à vérifier'", icon: 'fa-solid fa-eye' },
    { label: "Changer le statut des chambre en 'à vérifier'", icon: 'fa-solid fa-eye' },
  ];

  const linkedRoomIndex = planningCTX?.resource
    ?.find((t: Room) => t.RoomNumber === 'COMMUNICANTE')
    ?.children.findIndex((e: Room) => e.Id === resource.IdLinkedRoom);
  const currentRoomIndex = planningCTX?.resource
    ?.find((t: Room) => t.RoomNumber === 'COMMUNICANTE')
    ?.children.findIndex((e: Room) => e.Id === resource.Id);

  return (
    <div style={{ color: parent ? parent[0].TypeColor : '' }} className="toto" id={'Encap' + resource.Id}>
      <ContextMenu style={{ width: '350px', fontSize: '10pt' }} model={items} ref={cm} />

      {parent && (
        <span className="md-shift-resource-icon">
          <i className="fas fa-bed" style={{ margin: '5px' }}></i>
          {resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr'))}
        </span>
      )}
      <div>
        {!resource.children && (
          <>
            {!resource.Id.includes('°') && !resource.Id.includes(' ') && <></>}

            <div
              //   onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
              id={'A' + resource.Id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#fcfcfc',
              }}
            >
              <Tooltip
                title={
                  <>
                    <i className="fas fa-bed" style={{ margin: '5px' }}></i>
                    {resource.RoomDescription}
                    <hr style={{ margin: 0, padding: 0 }} />
                    <i style={{ margin: '5px' }} className="fa-solid fa-stairs"></i>
                    {resource.Floor}
                    <hr style={{ margin: 0, padding: 0 }} />
                    <i style={{ margin: '5px' }} className="fa-solid fa-broom"></i>
                    {resource.IsDirty === 0
                      ? t('labels.Chambrepropre')
                      : resource.IsDirty === 1
                        ? t('labels.Chambresale')
                        : resource.IsDirty === 2
                          ? t('labels.Chambreverifier')
                          : ''}
                    {resource.Size && (
                      <>
                        <hr style={{ margin: 0, padding: 0 }} />
                        <i style={{ margin: '5px' }} className="fa-regular fa-square"></i> {resource.Size} m²
                      </>
                    )}
                  </>
                }
              >
                <div
                  className={'A' + resource.Id}
                  style={{
                    width: '30%',

                    color:
                      resource.IsDirty === 0
                        ? ''
                        : resource.IsDirty === 1
                          ? colors.colorErrorMain
                          : resource.IsDirty === 2
                            ? 'yellow'
                            : 'darkorange',
                  }}
                >
                  <div style={{ position: 'absolute', left: 5, top: '4px' }}>
                    {resource.IsDirty === 0 ? (
                      ''
                    ) : resource.IsDirty === 1 ? (
                      <i className="fa-solid fa-broom"></i>
                    ) : resource.IsDirty === 2 ? (
                      <i className="fa-solid fa-eye"></i>
                    ) : resource.IsDirty === 8 ? (
                      <i className="fa-solid fa-bed"></i>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    style={{
                      fontSize: '10pt',
                      lineHeight: '29px',
                      fontWeight: 'bold',
                      backgroundColor: hexToRgba(resource.RoomColor),
                      color: colors.colorGreyAdminLTE,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {resource.RoomNumber.replace('[DEG]', '°')}
                  </div>

                  {resource.IdLinkedRoom != undefined && (
                    <i
                      style={{
                        transform: 'rotate(135deg)',
                        position: 'absolute',
                        top:
                          currentRoomIndex > linkedRoomIndex
                            ? currentRoomIndex % 2 === 0
                              ? '20px'
                              : '-5px'
                            : linkedRoomIndex % 2 === 0
                              ? '-5px'
                              : '20px',
                        left: '10px',
                        color: '#DB7400',
                      }}
                      className="fas fa-link"
                    ></i>
                  )}
                </div>
              </Tooltip>
              <div style={{ width: '70%', backgroundColor: hexToRgba(resource.TypeColor) }} id={'B' + resource.Id}>
                <Tooltip
                  title={
                    <>
                      {' '}
                      <i className="fas fa-bed" style={{ margin: '5px' }}></i>
                      {resource.RoomTypeDescription?.replace('[VIRTUAL]', t('labels.sr'))}
                      <hr style={{ margin: 0, padding: 0 }} />
                      <i className="fa-solid fa-people-line" style={{ margin: '5px' }}></i> {resource.PAX}{' '}
                      {t('labels.paxmax')}
                    </>
                  }
                >
                  <div
                    className={'B' + resource.Id}
                    style={{
                      textAlign: 'center',
                      borderRadius: '4px',

                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      padding: '5px',
                      textTransform: 'uppercase',
                      fontSize: '10pt',
                      whiteSpace: 'nowrap',
                      color: 'black',
                    }}
                  >
                    {resource.RoomType.replace('[VIRTUAL]', t('labels.sr'))}
                    {resource.IsForPmr && (
                      <i style={{ marginLeft: '5px', marginRight: '5px' }} className="fa-brands fa-accessible-icon"></i>
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomResourceBreakingType;
