import React from 'react';

import { Icon } from '@iconify/react';

export interface SmallBoxProps {
  type:
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'primary'
    | 'light'
    | 'indigo'
    | 'pink'
    | 'orange'
    | 'navy'
    | 'lime'
    | 'cyan'
    | 'gray-dark'
    | 'gray';
  icon?: string;
  currentValue: number[] | string[];
  lastValue: number[] | string[];
  title: string;
  navigateTo?: string;
  currentSort: number;
  footer: string[];
  handleChangeParent: (sort: number | null) => void;
}

const SmallBox = ({
  type = 'info',
  icon = 'ion-bag',
  currentValue,
  currentSort,
  handleChangeParent,
  lastValue,
  title,

  footer,
}: SmallBoxProps) => {
  return (
    <div className={`small-box bg-gradient-light`}>
      <div
        className={`bg-gradient-${type}`}
        style={{
          height: '5px',
          width: '100%',
          borderRadius: 0,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      ></div>
      <div className="inner">
        <h3>{currentValue[currentSort]}</h3>
        <p>{title}</p>
      </div>
      <div className="icon" style={{ minHeight: '10px' }}>
        <Icon
          icon={
            parseFloat(currentValue[currentSort] as string) === parseFloat(lastValue[currentSort] as string)
              ? 'mdi:approximately-equal'
              : icon
          }
          rotate={
            parseFloat(currentValue[currentSort] as string) > parseFloat(lastValue[currentSort] as string) ? 3 : 0
          }
        />
        {lastValue[currentSort] !== undefined && (
          <h6
            style={{
              textAlign: 'right',

              position: 'absolute',
              right: '25px',
              top: '80px',
            }}
          >
            N - 1 : {lastValue[currentSort]}
          </h6>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%' }} className={`bg-gradient-${type}`}>
        {footer.map((e, i) => (
          <div
            onClick={() => handleChangeParent(i)}
            key={i}
            className={i === currentSort ? 'active clickableDiv' : 'clickableDiv'}
          >
            {e}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmallBox;
