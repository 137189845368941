import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useLogger } from '@app/modules/logger/LoggerContext';
import { Room } from '@app/types/Hotel/room';
import { useFilters } from '@app/modules/filter/filterContext';
import { Dropdown } from 'primereact/dropdown';
import { useUser } from '@app/modules/user/UserContext';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useEstock } from '@app/modules/main/estock/EstockContext';
import { getDeltaInDays } from '@app/utils/dates';
import { Host, Rooms, upsertParam } from '@app/types/types';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

interface props {
  data: {
    RoomType: string;
    IdRoomType: string;
    Disponible: number;
    DateStart?: string;
    DateEnd?: string;
    RoomTypeList?: Rooms[];
    HostList: Host[];
    IdHost: Host;
  };
  actions: {
    hideSubForm: () => void;
  };
}
const MajStock: React.FC<props> = ({ data, actions }) => {
  const filtersCTX = useFilters();
  const userCTX = useUser();
  const estockCTX = useEstock();
  const [t] = useTranslation();
  const [dateStart, setDateStart] = useState(data.DateStart);
  const [dateEnd, setDateEnd] = useState(data.DateEnd);
  const [nbChambretoSend, setNbChambresToSend] = useState<number | null>(data.Disponible ?? 0);
  const [IdRoomType, setIdRoomType] = useState(data.RoomTypeList?.find((e) => e.id === data.IdRoomType));
  const [IdHost, setIdHost] = useState(
    data.HostList?.find((e) => e.id === data?.IdHost?.id) ?? { name: 'TOUS', id: 0 },
  );
  const [errorList, setErrorList] = useState<{ date: string; type: string }[]>([]);

  const [choice, setChoice] = useState(-1);

  const [roomToKeep, setRoomTokeep] = useState<number | null>(0);

  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = userCTX.authenticationInfos.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };

  useEffect(() => {
    if (data.RoomType) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        estockFilters: {
          ...prevState.estockFilters,
          TypeToDisplay: data.RoomType,
        },
      }));
    } else {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        estockFilters: {
          ...prevState.estockFilters,
          TypeToDisplay: null,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrorList([]);
  }, [estockCTX.EStock]);
  useEffect(() => {
    if (userCTX.authenticationInfos.selectedHotel?.IdHotel) {
      userCTX.getRoomsByIdHotel(userCTX.authenticationInfos.selectedHotel?.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX.authenticationInfos.selectedHotel?.IdHotel]);

  const updateEstock = () => {
    const params: upsertParam[] = [];

    let needConfirmation = false;
    for (let i = 0; i <= getDeltaInDays(moment(dateStart).toDate(), moment(dateEnd).toDate()); i++) {
      //faire le test de l'apporteur si tous
      if (IdHost.name === 'TOUS' || data.HostList.length === 1) {
        for (let j = 0; j < data.HostList.length; j++) {
          if (
            (IdRoomType?.name === 'TOUS' || IdRoomType === undefined) &&
            data.RoomTypeList?.filter((r) => r.name !== 'TOTAL')
          ) {
            for (let h = 0; h < data.RoomTypeList?.filter((r) => r.name !== 'TOTAL').length; h++) {
              const param = {
                Quantite:
                  (choice === 0
                    ? (estockCTX.EStock?.Partners.find((p) => p.IdHost === data.HostList[j].id)
                        ?.RoomTypes.find((r) => r.Id === data?.RoomTypeList?.filter((x) => x.name !== 'TOTAL')[h]?.id)
                        ?.Data.find(
                          (d) =>
                            moment(d.Date).format('YYYY-MM-DD') ===
                            moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
                        )?.Available ?? 0) - (roomToKeep ?? 0)
                    : nbChambretoSend) ?? 0,
                IdRoomType: data.RoomTypeList.filter((r) => r.name !== 'TOTAL')[h].id,
                IdHost: data.HostList[j].id,

                Date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
              };
              if (
                param.Quantite >
                (estockCTX.EStock?.Partners.find((p) => p.IdHost === data.HostList[j].id)
                  ?.RoomTypes.find((r) => r.Id === data?.RoomTypeList?.filter((x) => x.name !== 'TOTAL')[h]?.id)
                  ?.Data.find(
                    (d) => moment(d.Date).format('YYYY-MM-DD') === moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
                  )?.Available as number)
              ) {
                errorList.push({
                  date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD') as string,
                  type: data.RoomTypeList[h].name,
                });
                setErrorList(errorList);
                needConfirmation = true;
              }
              params.push(param);
            }
          } else {
            const param = {
              Quantite:
                (choice === 0
                  ? (estockCTX.EStock?.Partners.find((p) => p.IdHost === data.HostList[j].id)
                      ?.RoomTypes.find((r) => r.Id === IdRoomType?.id)
                      ?.Data.find(
                        (d) =>
                          moment(d.Date).format('YYYY-MM-DD') === moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
                      )?.Available ?? 0) - (roomToKeep ?? 0)
                  : nbChambretoSend) ?? 0,
              ...(IdRoomType?.id !== null &&
                IdRoomType?.id !== undefined &&
                IdRoomType?.id !== '0' && { IdRoomType: IdRoomType.id ?? 0 }),
              IdHost: data.HostList[j].id,

              Date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
            };
            if (
              param.Quantite >
              (estockCTX.EStock?.Partners.find((p) => p.IdHost === data.HostList[j].id)
                ?.RoomTypes.find((r) => r.Id === IdRoomType?.id)
                ?.Data.find(
                  (d) => moment(d.Date).format('YYYY-MM-DD') === moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
                )?.Available as number)
            ) {
              errorList.push({
                date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD') as string,
                type: IdRoomType?.name ?? '',
              });
              setErrorList(errorList);
              needConfirmation = true;
            }
            params.push(param);
          }
        }
      } else {
        const param = {
          Quantite:
            (choice === 0
              ? (estockCTX.EStock?.Partners.find((p) => p.IdHost === IdHost.id)
                  ?.RoomTypes.find((r) => r.Id === IdRoomType?.id)
                  ?.Data.find(
                    (d) => moment(d.Date).format('YYYY-MM-DD') === moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
                  )?.Available ?? 0) - (roomToKeep ?? 0)
              : nbChambretoSend) ?? 0,
          ...(IdRoomType?.id !== null &&
            IdRoomType?.id !== '0' &&
            IdRoomType?.id !== undefined && { IdRoomType: IdRoomType.id ?? 0 }),
          ...(IdHost !== null && IdHost !== undefined && { IdHost: IdHost.id ?? 0 }),

          Date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
        };
        if (
          param.Quantite >
          (estockCTX.EStock?.Partners.find((p) => p.IdHost === IdHost.id)
            ?.RoomTypes.find((r) => r.Id === IdRoomType?.id)
            ?.Data.find(
              (d) => moment(d.Date).format('YYYY-MM-DD') === moment(dateStart).add(i, 'day').format('YYYY-MM-DD'),
            )?.Available as number)
        ) {
          errorList.push({
            date: moment(dateStart).add(i, 'day').format('YYYY-MM-DD') as string,
            type: IdRoomType?.name ?? '',
          });
          setErrorList(errorList);
          needConfirmation = true;
        }
        params.push(param);
      }
    }
    if (needConfirmation === true) {
      confirmDialog({
        message: (
          <>
            <div>Certains stocks vous mettent en situations de sur-réservation potentielle !!!</div>
            {errorList.map((e, index) => (
              <div key={index}>
                {e.date} : {e.type}
              </div>
            ))}
          </>
        ),
        header: 'ATTENTION',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
        icon: 'pi pi-exclamation-triangle',
        accept: () =>
          estockCTX.updateEstock(params).finally(() => {
            estockCTX.getEstockData(
              moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD'),
              moment(filtersCTX.filters.estockFilters.DateEnd).format('YYYY-MM-DD'),
            );
            actions.hideSubForm();
          }),
        reject: () => {
          setErrorList([]);
        },
      });
    } else {
      if (choice === 2) {
        estockCTX
          .closeEstock(
            moment(dateStart).format('YYYY-MM-DD'),
            moment(dateEnd).format('YYYY-MM-DD'),
            IdHost.id !== 0 ? IdHost?.id : undefined,
            IdRoomType?.id !== '0' ? IdRoomType?.id : undefined,
          )
          .finally(() => {
            estockCTX.getEstockData(
              moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD'),
              moment(filtersCTX.filters.estockFilters.DateEnd).format('YYYY-MM-DD'),
            );
            actions.hideSubForm();
          });
      } else if (choice === 3) {
        estockCTX.sendEstock(dateStart as string, dateEnd as string, IdHost?.id, IdRoomType?.id).finally(() => {
          estockCTX
            .getEstockData(
              moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD'),
              moment(filtersCTX.filters.estockFilters.DateEnd).format('YYYY-MM-DD'),
            )
            .finally(() => {
              estockCTX.getEstockData(
                moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD'),
                moment(filtersCTX.filters.estockFilters.DateEnd).format('YYYY-MM-DD'),
              );
              actions.hideSubForm();
            });
        });
      } else {
        estockCTX.updateEstock(params).finally(() => {
          estockCTX.getEstockData(
            moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD'),
            moment(filtersCTX.filters.estockFilters.DateEnd).format('YYYY-MM-DD'),
          );
          actions.hideSubForm();
        });
      }
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <ConfirmDialog />

      <div style={{ textAlign: 'center', padding: '10px' }}>
        <i className="p-2 fa-solid fa-bed fa-2xl"></i>
        <i className="p-2 fa-solid fa-right-long fa-2xl"></i>
        <i className="p-2 fa-solid fa-globe fa-2xl"></i>
      </div>
      <div>
        {filtersCTX.filters.estockFilters && data.RoomTypeList != undefined && (
          <>
            {data.HostList.length > 1 && (
              <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: '10px' }}>
                {' '}
                Apporteur{' '}
                <Dropdown
                  value={IdHost ? IdHost : { name: 'TOUS', id: 0 }}
                  onChange={(e) => {
                    setIdHost(e.value);
                  }}
                  options={[{ name: 'TOUS', id: 0 }].concat(data.HostList.map((e) => e))}
                  optionLabel="name"
                  placeholder="Type de chambre"
                  style={{ width: '40%' }}
                />
              </div>
            )}
            <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: '10px' }}>
              {' '}
              Type de chambre :{' '}
              <Dropdown
                value={IdRoomType ?? { name: 'TOUS', id: '0' }}
                optionLabel="name"
                onChange={(e) => {
                  setIdRoomType(e.value);
                }}
                options={[{ name: 'TOUS', id: '0' }].concat(
                  data.RoomTypeList.map((e) => e).filter((e) => e.name !== 'TOTAL'),
                )}
                placeholder="Type de chambre"
                style={{ width: '40%' }}
              />
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: '10px' }}>
              <RadioButton
                disabled={
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 37)?.CanAccess === false
                }
                inputId="choice1"
                name=""
                value={0}
                onChange={(e) => setChoice(e.value)}
                checked={choice === 0}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Envoi du stock disponible moins{' '}
                {
                  <InputNumber
                    inputStyle={{ height: '40px', width: '40px' }}
                    style={{ height: '40px' }}
                    buttonLayout="horizontal"
                    showButtons
                    value={roomToKeep}
                    onChange={(e) => setRoomTokeep(e.value)}
                  ></InputNumber>
                }{' '}
                chambre(s)
              </label>
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex', padding: '10px' }}>
              <RadioButton
                disabled={
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 37)?.CanAccess === false
                }
                inputId="choice2"
                name=""
                value={1}
                onChange={(e) => setChoice(e.value)}
                checked={choice === 1}
              />
              <label htmlFor="ingredient2" className="ml-2">
                Envoi de{' '}
                {
                  <InputNumber
                    inputStyle={{ height: '40px', width: '40px' }}
                    style={{ height: '40px' }}
                    buttonLayout="horizontal"
                    showButtons
                    value={nbChambretoSend}
                    onChange={(e) => setNbChambresToSend(e.value)}
                  ></InputNumber>
                }{' '}
                chambre(s)
              </label>
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex', padding: '10px' }}>
              <RadioButton
                inputId="choice3"
                name=""
                value={2}
                disabled={
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 81)?.CanAccess === false
                }
                onChange={(e) => setChoice(e.value)}
                checked={choice === 2}
              />
              <label className="ml-2">Fermer le stock</label>
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex', padding: '10px' }}>
              <RadioButton
                inputId="choice3"
                name=""
                value={3}
                onChange={(e) => setChoice(e.value)}
                checked={choice === 3}
              />
              <label className="ml-2">Renvoyer le stock actuel</label>
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'space-around', display: 'flex', padding: '10px' }}>
              <label style={{ margin: 0 }}> {t('header.label.from')}</label>

              <Calendar
                dateFormat={getDateFormat(dateStart as string)}
                value={moment(dateStart).toDate()}
                minDate={moment(userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate).toDate()}
                todayButtonClassName={'hidden'}
                style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                onChange={(e) => {
                  setDateStart(moment(e.value).format('YYYY-MM-DD'));
                  if (dateStart && moment(e.value) > moment(dateEnd)) setDateEnd(moment(e.value).format('YYYY-MM-DD'));
                }}
                locale={userCTX.authenticationInfos?.language || 'fr'}
              />
              <label style={{ margin: 0 }}> {t('header.label.to')}</label>

              <Calendar
                dateFormat={getDateFormat(dateEnd as string)}
                minDate={moment(dateStart).toDate()}
                value={moment(dateEnd).toDate()}
                todayButtonClassName={'hidden'}
                style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                onChange={(e) => setDateEnd(moment(e.value).format('YYYY-MM-DD'))}
                locale={userCTX.authenticationInfos?.language || 'fr'}
              />
            </div>
            <div style={{ alignItems: 'center', justifyContent: 'space-around', display: 'flex', padding: '10px' }}>
              <Button
                disabled={choice < 0}
                style={{ width: '35%' }}
                icon="fa-solid fa-check"
                onClick={() => updateEstock()}
              ></Button>
            </div>
          </>
        )}{' '}
      </div>
    </div>
  );
};

export default MajStock;
