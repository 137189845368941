import { useEffect } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { KardexOperations, OperationsFilters } from '@app/types/types';
import { shortDateToLocaleString } from '@app/utils/dates';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const CompteAvoirGrid: React.FC = () => {
  const kardexCTX = useKardex();

  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const userCTX = useUser();
  const authData = userCTX.authenticationInfos; // userCTX.authenticationInfos;
  const [t] = useTranslation();

  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = authData?.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };
  useEffect(() => {
    if (authData?.selectedHotel?.IdHotel && kardexCTX.CurrentKardex?.Id) {
      kardexCTX.setOperationsFilters({
        ...kardexCTX.OperationsFilters,
        IdHotel: authData?.selectedHotel?.IdHotel,
        IdKardex: kardexCTX.CurrentKardex?.Id,
      });
      kardexCTX.getLastTenOperations(authData?.selectedHotel?.IdHotel, kardexCTX.CurrentKardex?.Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'space-between',
        height: '100%',
      }}
    >
      <>
        {kardexCTX.KardexOperations && (
          <DataTable
            value={kardexCTX?.KardexOperations}
            stripedRows
            scrollable
            rowHover
            className={(darkMode ? 'dark' : '') + ' normalDT compteAvoirDT'}
          >
            <Column
              headerTooltip="Date de facturation"
              body={(e: KardexOperations) => (
                <div>
                  {shortDateToLocaleString(
                    moment(e.DateInvoicing).toDate(),
                    userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                  )}
                </div>
              )}
              header={<i className="fa-regular fa-calendar"></i>}
            ></Column>
            <Column
              headerTooltip="Mode de règlement"
              field="PaymentMode"
              header={<i className="fa-solid fa-money-bill"></i>}
            ></Column>
            <Column
              headerTooltip="Montant encaissé"
              body={(e: KardexOperations) =>
                e.AmountInSet ? <div style={{ backgroundColor: 'greenyellow', padding: '5px' }}>{e.AmountIn}</div> : ''
              }
              header={<i className="fa-solid fa-arrow-down"></i>}
            ></Column>
            <Column
              headerTooltip="Montant utilisé"
              body={(e: KardexOperations) =>
                e.AmountOutSet ? <div style={{ backgroundColor: 'orange', padding: '5px' }}>{e.AmountOut}</div> : ''
              }
              header={<i className="fa-solid fa-arrow-up"></i>}
            ></Column>
            <Column
              headerTooltip="Destination"
              field="ReadablePaymentOf"
              header={<i className="fa-solid fa-flask"></i>}
            ></Column>
            <Column
              headerTooltip="Numéro de chambre"
              body={(e: KardexOperations) => (e.HasBooking ? e.RoomNumber : '')}
              header={<i className="fa-solid fa-bed"></i>}
            ></Column>
            <Column
              headerTooltip="Date d'arrivée"
              body={(e: KardexOperations) =>
                e.HasBooking
                  ? shortDateToLocaleString(
                      moment(e.StayDates.DateStart).toDate(),
                      userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                    )
                  : ''
              }
              header={<i className="fa-solid fa-plane-arrival"></i>}
            ></Column>

            <Column
              headerTooltip="Date de départ"
              body={(e: KardexOperations) =>
                e.HasBooking
                  ? shortDateToLocaleString(
                      moment(e.StayDates.DateEnd).toDate(),
                      userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                    )
                  : ''
              }
              header={<i className="fa-solid fa-plane-departure"></i>}
            ></Column>

            <Column
              headerTooltip="Numéro de facture"
              body={(e: KardexOperations) => (e.HasInvoice ? e.Invoice.Number : '')}
              header={<i className="fa-regular fa-file-lines"></i>}
            ></Column>
            <Column
              headerTooltip="Réservation"
              body={(e: KardexOperations) =>
                e.HasBookingLink ? (
                  <>
                    <Button style={{ width: '24px', height: '24px' }} icon="fa-solid fa-file-image"></Button>
                    {e.Booking.NResa}
                  </>
                ) : (
                  ''
                )
              }
              header={<i className="fa-regular fa-file-image"></i>}
            ></Column>
            <Column
              headerTooltip="Note"
              body={(e: KardexOperations) => e.Note}
              header={<i className="fa-regular fa-file"></i>}
            ></Column>
            <Column
              body={(e: KardexOperations) => e.DateTimeAndInitiales}
              header={<i className="fa-regular fa-user"></i>}
            ></Column>
          </DataTable>
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              height: '50px',
            }}
          >
            {' '}
            <label style={{ margin: 0 }}> {t('header.label.from')}</label>
            <Calendar
              touchUI={isMobile}
              value={new Date(kardexCTX?.OperationsFilters?.DateStart ?? 0)}
              showWeek
              inputStyle={{ width: '70%', height: '40px' }}
              style={{ width: '70%' }}
              dateFormat={getDateFormat(kardexCTX?.OperationsFilters?.DateStart ?? 0)}
              todayButtonClassName={'hidden'}
              onChange={(e) => {
                kardexCTX.setOperationsFilters({
                  ...kardexCTX.OperationsFilters,

                  DateStart: moment(e.value?.getTime()).toDate() ?? 0,
                });
              }}
              locale={authData?.language || 'fr'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              height: '50px',
            }}
          >
            <label style={{ margin: 0 }}>{t('header.label.to')} </label>

            <Calendar
              value={new Date(kardexCTX?.OperationsFilters?.DateEnd ?? 0)}
              inputStyle={{ width: '70%', height: '40px' }}
              dateFormat={getDateFormat(kardexCTX?.OperationsFilters?.DateEnd ?? 0)}
              style={{ width: '70%' }}
              todayButtonClassName={'hidden'}
              onChange={(e) =>
                kardexCTX.setOperationsFilters({
                  ...kardexCTX.OperationsFilters,

                  DateEnd: moment(e.value?.getTime()).toDate() ?? 0,
                })
              }
              locale={authData?.language || 'fr'}
            />
            {kardexCTX?.OperationsFilters !== null && (
              <Button
                style={{
                  bottom: '0',
                  backgroundColor: darkMode ? 'var(--color-primary-light)' : 'var(--color-primary-main)',

                  width: '35px',
                  height: '35px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  kardexCTX.getOperations(kardexCTX.OperationsFilters as OperationsFilters);
                }}
                icon="pi pi-refresh"
              ></Button>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default CompteAvoirGrid;
