import React, { useState } from 'react';

import { useMessages } from '@app/modules/MessageContext';
import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';
import { Message } from '@app/types/types';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
interface props {
  recipient: string;
}

const NewMessage: React.FC<props> = (props) => {
  const userCTX = useUser();
  const messageCTX = useMessages();
  const [messageToSend, setMessageToSend] = useState('');
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'white' }}>
      <InputText
        style={{
          backgroundColor: '#f0f0f0',
          borderRadius: '15px',
          height: '24px',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        value={messageToSend}
        onChange={(e) => setMessageToSend(e.target.value)}
      ></InputText>
      <Button
        icon="fa-solid fa-paper-plane fa-xs"
        style={{ height: '24px', width: '24px' }}
        onClick={() => {
          const newMessage: Message = {
            SenderId: userCTX.authenticationInfos.user?.Id ?? '',
            UserId: props.recipient,
            Message: messageToSend,
          };
          messageCTX.updateMessagesSended(newMessage);

          userCTX.sendToUser(props.recipient, messageToSend);
          setMessageToSend('');
        }}
      ></Button>
    </div>
  );
};

export default NewMessage;
