import { useEffect, useState } from 'react';

import BarChart from '@app/components/Common/charts/BarChart';
import PieChart from '@app/components/Common/charts/PieChart';
import RepartitionFilters from '@app/components/Common/filter/RepartitionFilters';
import { AnalytiqueProvider, useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { dateTo_DD_MM, shortOnlyMonthDateToLocaleString } from '@app/utils/dates';
import { roundOneDecimal } from '@app/utils/numbers';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const Repartition: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const filtersCTX = useFilters();
  const analyticsCtx = useAnalytics();
  const repartitionData = analyticsCtx.AnalyticsRepartition;
  const userCTX = useUser();
  const colors = [
    '#FF595E', // Rouge Vif
    '#FFCA3A', // Jaune Canari
    '#8AC926', // Vert Lime
    '#1982C4', // Bleu Ciel
    '#6A4C93', // Violet Vibrant
    '#FF9F1C', // Orange Éclatant
    '#38A3A5', // Turquoise
    '#FF61E2', // Magenta
    '#2CB67D', // Vert Émeraude
    '#0A1172', // Bleu Marine
    '#7B98E0', // Bleu Azur
    '#FF686B', // Corail
    '#A9E5BB', // Vert Menthe
    '#FFC857', // Jaune Moutarde
    '#842E2E', // Rouge Bordeaux
    '#8D5905', // Gris Bleu
    '#9B5DE5', // Mauve Doux
    '#FF6F59', // Orange Brûlé
    '#F77DFF', // Lilas
    '#7B9E42', // Vert Olive
    '#9A4D6F', // Bleu Pétrole
    '#A10035', // Grenat
    '#D2AA3A', // Or Pâle
    '#204623', // Vert Forêt
    '#731963', // Prune
    '#88FFF7', // Cyan Clair
    '#FFAAD2', // Rose Pastel
    '#FFD5CD', // Orange Pastel
    '#8BA3B3', // Bleu Gris
    '#F7FF58', // Jaune Citron
    '#688B2F', // Vert Mousse
    '#132A4D', // Bleu Foncé
    '#C1440E', // Rouge Terre
    '#430C5A', // Violet Sombre
    '#205E5E', // Turquoise Foncé
    '#FFC43D', // Jaune Safran
    '#FF007E', // Rose Shocking
    '#71C9CE', // Bleu Glacier
    '#A4DEB5', // Vert Pastel
    '#7C7B82', // Gris Taupe
  ];
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);

  useEffect(() => {
    if (filtersCTX.filters.repartitionFilters.needReload === true) {
      analyticsCtx.getAnalyticsRepartition(filtersCTX.filters.repartitionFilters);
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        repartitionFilters: {
          ...prevState.repartitionFilters,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.repartitionFilters]);

  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        <div className="row">
          <div className="col-lg-12 col-12">
            {(isMobile || isTablet || isConditionMet) && (
              <div
                className={`small-box`}
                style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
              >
                {' '}
                <div className="inner">
                  <RepartitionFilters></RepartitionFilters>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <AnalytiqueProvider>
              <>
                <div className="row" style={{ minHeight: '80vh' }}>
                  {repartitionData && repartitionData.length > 0 && (
                    <div className={repartitionData.length === 1 ? ' col-lg-6 col-12' : ' col-lg-12 col-12'}>
                      <div className={`small-box bg-gradient-light`}>
                        {repartitionData.length === 1 && (
                          <PieChart
                            options={{}}
                            height={'400px'}
                            showTitle={false}
                            title=""
                            data={{
                              labels: repartitionData[0].Datas.map((item) => item.Category),
                              datasets: [
                                {
                                  data: repartitionData[0].Datas.map((item) => roundOneDecimal(item.Value)),
                                  backgroundColor: colors.slice(0, repartitionData[0].Datas.length),
                                  borderColor: '#fff',
                                  borderWidth: 2,
                                },
                              ],
                            }}
                          ></PieChart>
                        )}
                        {repartitionData.length > 1 && (
                          <BarChart
                            stacked
                            minHeight="800px"
                            blueColor={false}
                            options={{
                              bar: { datasets: { barPercentage: 1, categoryPercentage: 1 } },

                              layout: { autoPadding: false },
                              datasets: { bar: { grouped: true, barPercentage: 1, categoryPercentage: 1 } },
                            }}
                            data={
                              repartitionData.map((e) => {
                                return {
                                  datasets: e.Datas.map((t, i) => {
                                    return {
                                      id: i,
                                      label: t.Category,
                                      backgroundColor: colors[i],

                                      borderColor: '#000000',
                                      borderWidth: 1,
                                      data: repartitionData.map(
                                        (g) => g.Datas.find((r) => r.Category === t.Category)?.Value ?? 0,
                                      ),
                                    };
                                  }),

                                  labels: repartitionData?.map((x) =>
                                    x.Id && filtersCTX?.filters?.repartitionFilters?.GroupBy === 0
                                      ? dateTo_DD_MM(new Date(x?.Id))
                                      : filtersCTX?.filters?.repartitionFilters?.GroupBy === 1
                                        ? x?.Id
                                        : filtersCTX?.filters?.repartitionFilters?.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              new Date(x?.Id),
                                              userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                                            )
                                          : x?.Id,
                                  ),
                                };
                              })[0]
                            }
                          ></BarChart>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            </AnalytiqueProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Repartition;
