/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import Filters from '@app/components/Common/filter/Filter';
import SmallBox from '@app/components/Common/small-box/SmallBox';
import DataGridView from '@app/components/Desktop/Table/dataGridView/DataGridView';
import TodayNewBookingDataTable from '@app/components/Desktop/Table/todayNewBookingDataTable/TodayNewBookingDataTable';
import OneRectangleLoader from '@app/modules/ContentLoader/OneRectangleLoader';
import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { formatPrice } from '@app/utils/formatCurrency';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import moment from 'moment';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';

const Dashboard = () => {
  const [t] = useTranslation();
  const userCTX = useUser();
  const dashboardCTX = useDashboard();
  const [isFirstCall, setIsFirstCall] = useState(true);
  const kpisData = dashboardCTX.KPIS?.Kpis;
  const bookingsData = dashboardCTX.KPIS?.Bookings;
  const currencyData = dashboardCTX.KPIS?.Currency;
  const filtersCTX = useFilters();
  const kpiParams = filtersCTX.filters.dashboardFilters;
  const gridData = dashboardCTX.GridData;
  const todaysNewBookin = dashboardCTX.TodayNewBooking;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const location = useLocation();
  const { orientation } = useMobileOrientation();
  const [currentSort, setCurrentSort] = useState(0);
  const [currentSort2, setCurrentSort2] = useState(0);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');
  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);
  useEffect(() => {
    if (filtersCTX.filters.dashboardFilters.needReload === true && isTech !== undefined && isFirstCall === false) {
      dashboardCTX.getAdminStat({
        dashboardFilters: kpiParams,
        params: {
          isTech,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
      });
      dashboardCTX.getGridData({
        dashboardFilters: kpiParams,
        params: {
          isTech,
          selectedHotel: null,
        },
      });

      dashboardCTX.getTodayNewBooking({
        dashboardFilters: kpiParams,
        params: {
          isTech,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
      });

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        dashboardFilters: {
          ...prevState.dashboardFilters,
          needReload: false,
          hasBeenModified: false,
        },
      }));
    }
  }, [
    dashboardCTX,
    filtersCTX,
    filtersCTX.filters.dashboardFilters.needReload,
    isTech,
    kpiParams,
    userCTX.authenticationInfos.selectedHotel,
  ]);

  useEffect(() => {
    if (isFirstCall === true && dashboardCTX && kpiParams && isTech != undefined && isTech !== null) {
      dashboardCTX.getGridData({
        dashboardFilters: kpiParams,
        params: {
          isTech,
          selectedHotel: null,
        },
      });
      dashboardCTX.getKPIS({
        params: {
          isTech,
          params: kpiParams.TypeFilters,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
        dashboardFilters: kpiParams,
      });
      dashboardCTX.getTodayNewBooking({
        dashboardFilters: kpiParams,
        params: {
          isTech,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
      });
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        dashboardFilters: {
          ...prevState.dashboardFilters,
          needReload: false,
          hasBeenModified: false,
        },
      }));

      setIsFirstCall(false);
    }
  }, [isFirstCall, dashboardCTX, kpiParams, isTech, userCTX.authenticationInfos.selectedHotel]);
  moment.locale(userCTX.authenticationInfos?.user?.Culture?.split('-')[1]);

  return (
    <div style={{ paddingTop: '20px' }}>
      {userCTX.authenticationInfos.listHotel?.length === 1 && <ContentHeader title={t('main.label.homePage')} />}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12">
              {(isMobile || isTablet || isConditionMet) && (
                <div
                  className={`small-box`}
                  style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
                >
                  {isTech && (
                    <div className="inner">{location.pathname === '/dashboard' ? <Filters></Filters> : <></>}</div>
                  )}
                  <div className="inner">{location.pathname === '/' ? <Filters></Filters> : <></>}</div>
                </div>
              )}
            </div>
          </div>
          {isTech === false && kpisData === null && selectedHotel !== null && (
            <>
              <div className="row">
                <div className="col-lg-3 col-6">
                  <OneRectangleLoader height="150px"></OneRectangleLoader>
                </div>
                <div className="col-lg-3 col-6">
                  <OneRectangleLoader height="150px"></OneRectangleLoader>
                </div>
                <div className="col-lg-3 col-6">
                  <OneRectangleLoader height="150px"></OneRectangleLoader>
                </div>
                <div className="col-lg-3 col-6">
                  <OneRectangleLoader height="150px"></OneRectangleLoader>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-6">
                  <OneRectangleLoader height="150px"></OneRectangleLoader>
                </div>
              </div>
            </>
          )}

          {kpisData !== null &&
            kpisData !== undefined &&
            bookingsData !== null &&
            currencyData !== undefined &&
            currencyData !== null && (
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-sm-12">
                  <h5>Occupation</h5>
                  <hr />
                  <div
                    className={`small-box bg-gradient-light`}
                    style={{ paddingTop: '10px', boxShadow: 'none', borderBottom: '1px solid #e0e0e0' }}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.TO')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            parseFloat(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TO')[0].CurrentYear.Day.toString(),
                            ).toFixed(2) + ' %',
                            parseFloat(
                              kpisData
                                .filter((e: DashBoardData) => e.Type === 'TO')[0]
                                .CurrentYear.MonthToDate.toString(),
                            ).toFixed(2) + ' %',
                            parseFloat(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TO')[0].CurrentYear.Month.toString(),
                            ).toFixed(2) + ' %',
                          ]}
                          lastValue={[
                            parseFloat(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TO')[0].LastYear.Day.toString(),
                            ).toFixed(2) + ' %',
                            parseFloat(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TO')[0].LastYear.MonthToDate.toString(),
                            ).toFixed(2) + ' %',
                            parseFloat(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TO')[0].LastYear.Month.toString(),
                            ).toFixed(2) + ' %',
                          ]}
                          type="primary"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.IF')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].CurrentYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].CurrentYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].CurrentYear.Month,
                          ]}
                          lastValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].LastYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].LastYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'IF')[0].LastYear.Month,
                          ]}
                          type="indigo"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('labels.occupiedRoom')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].CurrentYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].CurrentYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].CurrentYear.Month,
                          ]}
                          lastValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].LastYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].LastYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'Occupied')[0].LastYear.Month,
                          ]}
                          type="navy"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.ALOS')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].CurrentYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].CurrentYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].CurrentYear.Month,
                          ]}
                          lastValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].LastYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].LastYear.MonthToDate,

                            kpisData.filter((e: DashBoardData) => e.Type === 'ALOS')[0].LastYear.Month,
                          ]}
                          type="orange"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        {bookingsData && (
                          <SmallBox
                            currentSort={currentSort2}
                            handleChangeParent={(t) => setCurrentSort2(t as number)}
                            title={t('smallbox.label.FROM24')}
                            icon="material-symbols:book-outline"
                            currentValue={[bookingsData.Reservations, bookingsData.Cancellations]}
                            lastValue={[]}
                            type="danger"
                            footer={[t('smallbox.label.Reservations'), t('smallbox.label.Cancellation')]}
                          ></SmallBox>
                        )}
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.NbStays')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].CurrentYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].CurrentYear.MonthToDate,
                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].CurrentYear.Month,
                          ]}
                          lastValue={[
                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].LastYear.Day,

                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].LastYear.MonthToDate,
                            kpisData.filter((e: DashBoardData) => e.Type === 'NbStays')[0].LastYear.Month,
                          ]}
                          type="gray-dark"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-sm-12 ">
                  <h5>Revenu</h5>
                  <hr />
                  <div
                    className={`small-box bg-gradient-light`}
                    style={{ paddingTop: '10px', boxShadow: 'none', borderBottom: '1px solid #e0e0e0' }}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.PM')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].CurrentYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].CurrentYear.MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].LastYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].LastYear.MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'PM')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="warning"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.RevPar')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].CurrentYear.Day,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].CurrentYear.MonthToDate,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].LastYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].LastYear.MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPar')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="success"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('prevision.label.PMH')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].CurrentYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].CurrentYear.MonthToDate,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].LastYear.Day,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].LastYear.MonthToDate,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RevPor')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="pink"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.GlobalRevenue')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].CurrentYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].CurrentYear
                                .MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].LastYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].LastYear.MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'GlobalRevenue')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="lime"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>{' '}
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.RoomRevenue')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].CurrentYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].CurrentYear
                                .MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].LastYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].LastYear.MonthToDate,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'RoomRevenue')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="gray"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-sm-12 ">
                        <SmallBox
                          currentSort={currentSort}
                          handleChangeParent={(t) => setCurrentSort(t as number)}
                          title={t('smallbox.label.TurnOver')}
                          icon="iconoir:arrow-br"
                          currentValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].CurrentYear.Day,
                              currencyData,
                            ),
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].CurrentYear.MonthToDate,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].CurrentYear.Month,
                              currencyData,
                            ),
                          ]}
                          lastValue={[
                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].LastYear.Day,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].LastYear.MonthToDate,
                              currencyData,
                            ),

                            formatPrice(
                              kpisData.filter((e: DashBoardData) => e.Type === 'TurnOver')[0].LastYear.Month,
                              currencyData,
                            ),
                          ]}
                          type="cyan"
                          footer={[
                            t('smallbox.label.Day'),
                            t('smallbox.label.MonthToDate'),
                            t('smallbox.label.CompleteMonth'),
                          ]}
                        ></SmallBox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {gridData === null && (selectedHotel !== null || isTech === false) && (
            <div className="row">
              <div className="col-lg-12 col-12">
                <OneRectangleLoader height="400px"></OneRectangleLoader>
              </div>
            </div>
          )}
          {gridData && (
            <div className="row">
              <div className="col-lg-12 col-12">
                <DataGridView fullComponentSize={true} noTitle={true}></DataGridView>
              </div>
              {/***
            <BarChart data={fakeBarData} options={chartOptions} />
            <DoughnutChart
              title={t('chart.doughnut.title')}
              data={fakeDoughnutData}
              options={chartOptions}
              showTitle
            />
            <DoughnutChart
              showTitle={false}
              title={t('chart.doughnut.title')}
              data={fakeDoughnutData2}
              options={chartOptions}
            ></DoughnutChart>
            <LineChart data={fakeBarData}></LineChart>
*/}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
