import { ReactNode, useEffect, useRef, useState } from 'react';

import { getChartColors } from '@app/components/Common/charts/chartOption';
import colors from '@app/styles/colors';
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface props {
  data: ChartData<'doughnut', number[], unknown>;
  options: ChartOptions;
  title: ReactNode;
  showTitle: boolean;
  total?: number;
}

const DoughnutChart: React.FC<props> = ({ data, options, title, showTitle, total }) => {
  const chartRef = useRef<ChartJS<'doughnut'>>(null);
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({
    datasets: [],
  });

  const [legendItems, setLegendItems] = useState([<div key="eieie"></div>]);
  const [valueItems, setValueItems] = useState<number[] | string[] | undefined>([]);

  const chartColors = getChartColors();

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const tempData = {
      ...data,

      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: [
          colors.colorPrimaryMain,
          colors.colorSecondaryMain,
          colors.colorSuccessMain,
          colors.colorWarningMain,
          colors.colorPurple,
          '#ff0000',
          '#456946',
        ],
        data: dataset.data.map((_, index) => dataset.data[index]), // Hide values initially
      })),
    };
    setValueItems(tempData?.datasets[0].data);
    setLegendItems(
      tempData?.labels?.map((label, index) => (
        <div
          key={'legend' + index}
          // onClick={() => toggleVisibility(index)}
          className={`legend-item legend-item-visible`}
          style={{
            //   cursor: 'pointer',
            color: chartColors[index],
            position: 'relative',
            textTransform: 'uppercase',
          }}
        >
          <span
            style={{
              color: chartColors[index],
              position: 'relative',

              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            {label as ReactNode}
          </span>
        </div>
      )) || [<></>],
    );
    setChartData(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      style={{
        padding: '10px',
        width: '100%',
      }}
    >
      {showTitle && (
        <>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              padding: 2,
              fontSize: 17,
              fontWeight: 'bolder',
              textTransform: 'uppercase',
              width: '100%',
            }}
          >
            {title}
          </Typography>
          <Divider variant="middle" />
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '10pt',
        }}
      >
        <div className="custom-legend" key="legend">
          {legendItems}
        </div>
        <div className="custom-legend">
          {valueItems?.map((e, index) => (
            <div key={'item' + index}>
              <span
                style={{
                  color: chartColors[index],
                  position: 'relative',

                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
              >
                {e}
              </span>
            </div>
          )) || [<>.</>]}
        </div>

        <div
          style={{
            height: '116px',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Doughnut
            ref={chartRef}
            data={chartData}
            options={{
              ...options,
              cutout: 40,
              plugins: { legend: { display: false } },
              scales: {},
            }}
            style={{ padding: 5 }}
          />
          <span style={{ position: 'relative', top: '-75%', fontSize: '38px', fontWeight: 'bold' }}> {total}</span>
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
