/**
 * Package import
 */
import React, { CSSProperties } from 'react';

import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { CountriesEntity, CustomerCulturesEntity } from '@app/types/dataFactory';
import { Dropdown } from 'primereact/dropdown';
interface props {
  data?: CountriesEntity;
  searchMode: string;
  placeholder?: string;
  style?: CSSProperties;
  showLibelle?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const CountryDropdown: React.FC<props> = (props) => {
  const DataFactoryCTX = useDataFactory();
  const selectedCountryTemplate = (option: CountriesEntity) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.Libelle}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.Id.toLowerCase()}`}
            style={{ width: '18px' }}
          />
          {props.showLibelle && option.Libelle}
          {props.searchMode === 'PhoneCode' ? `(+${option.PhoneCode})` : ''}
        </div>
      );
    } else {
      if (DataFactoryCTX.DataDictionnary.Countries) {
        return (
          <>
            <div className="flex align-items-center">
              <img
                alt={'Indeterminé'}
                src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`mr-2 flag flag-`}
                style={{ width: '18px' }}
              />
              {DataFactoryCTX?.DataDictionnary?.Countries[0]?.Libelle}
            </div>
          </>
        );
      }
    }
  };
  const countryOptionTemplate = (option: CountriesEntity) => {
    return (
      <div className="flex align-items-center" style={{ display: 'flex' }}>
        <img
          alt={option.Libelle}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.Id.toLowerCase()}`}
          style={{ width: '18px' }}
        />
        {option.Libelle}
        {props.searchMode === 'PhoneCode' ? `(+${option.PhoneCode})` : ''}
      </div>
    );
  };
  return (
    <>
      {props && DataFactoryCTX?.DataDictionnary?.Countries && (
        <div title={props.tooltip}>
          <Dropdown
            tooltip={props.tooltip}
            disabled={props.disabled}
            style={props.style}
            value={props.data}
            options={DataFactoryCTX?.DataDictionnary?.Countries ?? []}
            optionLabel="Libelle"
            placeholder={props.placeholder}
            filter
            filterBy={props.searchMode === 'Libelle' ? 'Libelle' : 'PhoneCode'}
            filterMatchMode="contains"
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            className="w-full md:w-14rem"
          />
        </div>
      )}
    </>
  );
};

export default CountryDropdown;
