import { ColumnMeta } from '@app/types/types';
import { isInteger, isNumber } from 'lodash';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';

export const exportToExcel = (
  data: any,
  exportedColumns: ColumnMeta[],
  filename: string,
  datestart: string,
  dateend: string,
) => {
  // Filtrer les colonnes en fonction de `exportedColumns`
  const filteredData = data.map((item: any) => {
    let filteredItem: any = {};
    exportedColumns.forEach((column: any) => {
      if (item[column.field] !== undefined) {
        filteredItem[column.header] =
          isNumber(item[column.field]) && !isInteger(item[column.field])
            ? parseFloat(item[column.field].toFixed(2))
            : item[column.field];
      } else {
        filteredItem[column.header] =
          isNumber(item[column.header]) && !isInteger(item[column.header])
            ? parseFloat(item[column.header].toFixed(2))
            : item[column.header]; // Utiliser le nom du header comme clé
      }
    });
    return filteredItem;
  });

  // Créer le fichier Excel avec les données filtrées
  const worksheet = XLSX.utils.json_to_sheet(filteredData);

  // Appliquer le style aux lignes (par exemple, couleur bleue)
  const range = XLSX.utils.decode_range(worksheet['!ref']!);
  for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
      if (!worksheet[cellAddress]) continue;

      // Appliquer la couleur de fond bleue
      worksheet[cellAddress].s = {
        fill: {
          fgColor: { rgb: 'ADD8E6' }, // Code couleur RGB pour bleu clair
        },
      };
    }
  }

  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  saveAsExcelFile(
    excelBuffer,
    filename + '_' + moment(datestart).format('YYYY-MM-DD') + '_' + moment(dateend).format('YYYY-MM-DD'),
  );
};

const saveAsExcelFile = (buffer: any, fileName: string) => {
  import('file-saver').then((module) => {
    if (module && module.default) {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  });
};
