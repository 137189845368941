import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment-timezone';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EstockFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const filtersCTX = useFilters();

  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const [t] = useTranslation();
  const authData = userCTX.authenticationInfos;
  const rooms = userCTX.authenticationInfos.roomOfSelectedHotel;
  const [typeMode, setTypeMode] = useState(0);
  const [isFirstCall, setIsFirstCall] = useState(true);

  const [isLocaleSet, setIslocaleSet] = useState(false);
  const iconTemplate = (option: SelectItem) => {
    if (option.icon) {
      return <i className={option.icon.toString()}></i>;
    } else {
      return option.title;
    }
  };
  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  useEffect(() => {
    if (filtersCTX && filtersCTX.filters.estockFilters && isFirstCall && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        estockFilters: {
          ...prevState.estockFilters,
          DateStart: moment(selectedHotel?.CurrentPlanningDate).toDate().getTime(),
          DateEnd: moment(selectedHotel?.CurrentPlanningDate).add(21, 'day').toDate().getTime(),
          SelectedHotels: selectedHotel,
          IdHotel: selectedHotel.IdHotel,
        },
      }));
      setIsFirstCall(false);
    }
  }, [filtersCTX, filtersCTX.filters.estockFilters, isFirstCall, selectedHotel]);

  useEffect(() => {
    if (filtersCTX && filtersCTX.filters.estockFilters && isTech === true && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        estockFilters: {
          ...prevState.estockFilters,
          SelectedHotels: selectedHotel,
          IdHotel: selectedHotel.IdHotel,
        },
      }));
      setIsFirstCall(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTech, selectedHotel]);

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {filtersCTX && isMobile && (
        <Accordion activeIndex={0}>
          <AccordionTab header={t('labels.filters')} tabIndex={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: colors.colorGreyAdminLTE,
                padding: '10px',
              }}
            >
              <Calendar
                value={new Date(filtersCTX.filters.estockFilters?.DateStart as Date)}
                todayButtonClassName={'hidden'}
                style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                dateFormat="dd/mm/yy"
                onChange={(e) =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    estockFilters: {
                      ...prevState.estockFilters,
                      hasBeenModified: true,

                      DateStart: moment(e.value?.getTime()).toDate(),
                      DateEnd: moment(e.value).add(21, 'day').toDate(),
                    },
                  }))
                }
                locale={authData?.language || 'fr'}
              />
              <Button
                style={{
                  marginRight: '10px',
                  marginLeft: '10px',
                  borderRadius: '4px',
                  backgroundColor: filtersCTX.filters.previsionFilters.hasBeenModified
                    ? colors.colorSuccessMain
                    : darkMode
                      ? 'var(--color-primary-light)'
                      : 'var(--color-primary-main)',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                icon="pi pi-refresh"
                onClick={() => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    previsionFilters: {
                      ...prevState.previsionFilters,
                      needReload: true,
                      hasBeenModified: false,
                    },
                  }));
                }}
              ></Button>
            </div>
          </AccordionTab>
        </Accordion>
      )}
      {!isMobile &&
        isLocaleSet &&
        filtersCTX.filters.planningFilters.CurrentSort !== null &&
        filtersCTX.filters.planningFilters && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Calendar
                value={new Date(filtersCTX.filters.estockFilters?.DateStart as Date)}
                todayButtonClassName={'hidden'}
                style={{ marginLeft: '10px', marginRight: '10px', width: '100px' }}
                dateFormat="dd/mm/yy"
                onChange={(e) =>
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    estockFilters: {
                      ...prevState.estockFilters,
                      hasBeenModified: true,

                      DateStart: moment(e.value?.getTime()).toDate(),
                      DateEnd: moment(e.value).add(21, 'day').toDate(),
                    },
                  }))
                }
                locale={authData?.language || 'fr'}
              />
            </div>
          </>
        )}
    </div>
  );
};

export default EstockFilters;
