import React, { createContext, ReactNode, useContext, useState } from 'react';

import { EStock, upsertParam } from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment-timezone';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState

interface EstockContextState {
  EStock: EStock | null;
  getEstockData: (DateStart: string, DateEnd: string) => Promise<MedialogResponse | undefined>;
  closeEstock: (
    DateStart: string,
    DateEnd: string,
    IdHost?: number,
    IdRoomType?: string,
  ) => Promise<number | undefined>;
  sendEstock: (DateStart: string, DateEnd: string, IdHost?: number, IdRoomType?: string) => Promise<number | undefined>;
  updateEstock: (params: upsertParam[]) => Promise<number | undefined>;
}

// Create a context to hold your params with default values
export const EstockContext = createContext<EstockContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type EstockProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const EstockProvider: React.FC<EstockProviderProps> = (props) => {
  const [EStock, setEStock] = useState<EStock | null>(null);

  const getEstockData = async (DateStart: string, DateEnd: string): Promise<MedialogResponse | undefined> => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const response = await axios.post(
        '/Estock',
        { DateStart: moment(DateStart).format('YYYY-MM-DD'), DateEnd: moment(DateEnd).format('YYYY-MM-DD') },
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const responseData: MedialogResponse = await response.data;
      setEStock(responseData.Data as EStock);

      return responseData;
      //    setGridGlobal(responseData.Data.Hotels);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const closeEstock = async (
    DateStart: string,
    DateEnd: string,
    IdHost?: number,
    IdRoomType?: string,
  ): Promise<number | undefined> => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const response = await axios.post(
        '/Estock/Close',
        {
          DateStart: moment(DateStart).format('YYYY-MM-DD'),
          DateEnd: moment(DateEnd).format('YYYY-MM-DD'),
          IdHost: IdHost,
          IdRoomType: IdRoomType,
        },
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      } else {
        return 1;
      }

      //    setGridGlobal(responseData.Data.Hotels);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const sendEstock = async (
    DateStart: string,
    DateEnd: string,
    IdHost?: number,
    IdRoomType?: string,
  ): Promise<number | undefined> => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const response = await axios.post(
        '/Estock/Send',
        {
          DateStart: moment(DateStart).format('YYYY-MM-DD'),
          DateEnd: moment(DateEnd).format('YYYY-MM-DD'),
          IdHost: IdHost !== 0 ? IdHost : null,
          IdRoomType: IdRoomType !== '0' ? IdRoomType : null,
        },
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      } else {
        return 1;
      }

      //    setGridGlobal(responseData.Data.Hotels);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const updateEstock = async (params: upsertParam[]): Promise<number | undefined> => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const response = await axios.post(
        '/Estock/Update',

        params,
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      } else {
        return 1;
      }

      //    setGridGlobal(responseData.Data.Hotels);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <EstockContext.Provider value={{ EStock, getEstockData, closeEstock, sendEstock, updateEstock }}>
      {props.children}
    </EstockContext.Provider>
  );
};

// Create a custom hook to access the params
export function useEstock(): EstockContextState {
  const estockContext = useContext(EstockContext);
  if (estockContext === undefined) {
    throw new Error('useEstock must be used within a EstockProvider');
  }
  return estockContext;
}
